.footer {
	background: $colorFooterBg;
	padding: 81px 0 89px;
	&__top {
		display: flex;
		justify-content: space-between;
		padding-right: 89px;
		&:not(:last-child) {
			margin-bottom: 38px;
		}
	}
	&__bottom {
		display: flex;
		justify-content: flex-end;
	}
	&-title {
		color: $colorOrange;
		&:not(:last-child) {
			margin-bottom: 31px;
		}
	}
	&__box {
		min-width: 416px;
	}
	&__products {
		&__list {
			li {
				a {
					color: $colorWhite;
					font-size: 40px;
					font-family: "Gotham Extra";
					font-weight: 200;
					font-style: normal;
					letter-spacing: normal;
					line-height: 1.925;
					display: flex;
					&.active,
					&:hover {
						color: $colorOrange;
					}

					&.inactive {
						color: $colorGray;
						pointer-events: none;
						&:hover {
							color: $colorGray;
						}
					}
				}
			}
		}
		.footer {
			&-title {
				&:not(:last-child) {
					margin-bottom: 7px;
				}
			}
		}
	}
	&__contact {
		&__list {
			li {
				max-width: 200px;
				&:not(:last-child) {
					margin-bottom: 23px;
				}
				a,
				p {
					color: $colorWhite;
					display: block;
					&:not(:last-child) {
						margin-bottom: 2px;
					}
				}
				a {
					transition: opacity 0.5s;
					&:hover {
						opacity: 0.6;
						color: $colorWhite;
					}
				}
			}
		}
	}
	&__subscribe {
		&__form {
			display: flex;
			align-items: center;
			&__input {
				color: $colorWhite;
				background: transparent;
				border: none;
				border-bottom: 1px solid $colorGray;
				width: 410px;
				height: 40px;
				padding: 0px 0 13px;
				border-radius: 0;
				box-shadow: none;
				&:not(:last-child) {
					margin-right: 28px;
				}
				&:focus {
					border-bottom: 1px solid $colorWhite;
				}
				&:hover {
					border-color: $colorWhite;
				}
			}
			&__send {
				.btn {
					width: 30px;
					height: 32px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.icon {
					font-size: 31px;
					line-height: 1;
					color: $colorOrange;
				}
			}
		}
	}
	&__info {
		width: 100%;
		max-width: 556px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&__terms {
		color: $colorOrange;
		ul {
			display: flex;
			align-items: center;
			li {
				position: relative;
				&:not(:last-child) {
					margin-right: 5px;
					&:after {
						content: ",";
						position: absolute;
						left: 100%;
						bottom: 0;
					}
				}
				a {
					color: $colorOrange;
					text-transform: uppercase;
					transition: opacity 0.5s;
					&:hover {
						opacity: 0.6;
						color: $colorOrange;
					}
				}
			}
		}
	}
	&__soc {
		display: flex;
		align-items: center;
		li {
			&:not(:last-child) {
				margin-right: 23px;
			}
			a {
				display: flex;
				color: $colorWhite;
				transition: opacity 0.5s;
				&:hover {
					opacity: 0.6;
					color: $colorWhite;
				}
				.icon {
					font-size: 15px;
					line-height: 1;
				}
			}
		}
	}
}
