.btn {
	position: relative;
	height: 32px;
	color: $colorAlabaster;
	font-size: 13px;
	font-weight: 300;
	// font-family: "Gotham", sans-serif;

	font-family: "Gotham Book", sans-serif;
	line-height: 32px;
	text-align: center;
	text-decoration: none;
	padding: 0;
	transition: all 0.3s ease;
	outline: none;
	cursor: pointer;

	border: none;
	background: transparent;
	text-transform: uppercase;

	display: flex;
	align-items: center;

	&:hover,
	&:active {
		color: $colorAlabaster;
		box-shadow: none;
		outline: none;
	}
	&:focus {
		outline: none;
		box-shadow: none;
	}

	&.disabled,
	&[disabled] {
		pointer-events: none;
		opacity: 0.5;
		cursor: auto;
	}
	&-icon {
		span {
			&:first-child {
				margin-right: 19px;
			}
			&:not(:first-child) {
				margin-left: 19px;
			}
		}

		.icon {
			color: $colorOrange;
			font-size: 32px;
		}
	}
	&-fs-16 {
		font-size: 16px;
	}
	&-hover {
		&-icon-size {
			.icon {
				transition: font-size 0.5s;
			}
			&:hover {
				.icon {
					font-size: 36px;
				}
			}
		}
		&-move {
			&-right {
				.icon,
				span {
					transition: transform 0.5s;
				}
				&:hover {
					.icon {
						&:not(:last-child) {
							transform: translateX(10px);
						}
						&:last-child {
							transform: translateX(5px);
						}
					}
					span {
						&:not(:last-child) {
							transform: translateX(10px);
						}
						&:last-child {
							transform: translateX(5px);
						}
					}
				}
			}
			&-left {
				.icon,
				span {
					transition: transform 0.5s;
				}
				&:hover {
					.icon {
						&:not(:last-child) {
							transform: translateX(-5px);
						}
						&:last-child {
							transform: translateX(-10px);
						}
					}
					span {
						&:not(:last-child) {
							transform: translateX(-5px);
						}
						&:last-child {
							transform: translateX(-10px);
						}
					}
				}
			}
		}
	}
}
