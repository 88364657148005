.main {
	&-banner {
		position: relative;
		.container {
			height: 100%;
		}
		&__inner {
			position: relative;
			display: flex;
			flex-direction: column;
			height: 100%;
			// padding-bottom: 80px;
			z-index: 2;

			// padding: 90px 0;
		}
		&__background {
			img,
			video {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
			&.v2 {
				.main-banner__background-wrapper {
					position: absolute;
					top: 0;
					right: 0;
					left: auto;
					bottom: 0;
					width: 49.27%;
					display: block;
					overflow: hidden;
				}
				// img {
				// 	width: 49.27%;
				// 	right: 0;
				// 	left: auto;
				// }
			}
		}
		&__breadcrumbs {
			position: absolute;
			top: 203px;
			left: 0;
		}
		&__title {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			text-transform: uppercase;
			z-index: 2;
			&.v3 {
				max-width: 668px;
				top: 53.3%;
			}
		}
		&__content {
			margin-top: auto;
			&.v2,
			&.v3 {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
			}
		}
		&__text {
			max-width: 559px;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;

			&:not(:last-child) {
				margin-bottom: 24px;
			}
			&.v2,
			&.v3 {
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				&:not(:last-child) {
					margin-bottom: 0px;
				}
			}
		}
		&__bottom {
			display: flex;
			justify-content: space-between;
			align-items: center;
			&.v2 {
				width: 100%;
				max-width: 560px;
			}
		}
		&__img {
			overflow: hidden;
		}
	}
	&-info {
		// padding:  80px 0;
		background: $colorBlack;
		&__inner {
			// padding: 160px 0 80px;
		}
		&__top {
			&:not(:last-child) {
				margin-bottom: 53px;
			}
		}
		&__content {
			display: flex;
			.main-info__box {
				&:not(:last-child) {
					margin-right: 28px;
				}
			}
		}
		&__box {
			flex: 1 0 0;
			&-img {
				position: relative;
				padding-bottom: 56%;
				width: 100%;
				overflow: hidden;
				z-index: 2;
				&:not(:last-child) {
					margin-bottom: 34px;
				}
				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;

					transition: transform 1.5s;
				}
			}
			&-content {
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				position: relative;
				z-index: 1;
			}
			&-heading {
				color: $colorGray;
				&:not(:last-child) {
					margin-bottom: 13px;
				}
			}
			&-title {
				&:not(:last-child) {
					margin-bottom: 27px;
				}
				h3 {
					font-size: 30px;
				}
			}
			&-text {
				max-width: 565px;
				color: $colorWhite;
				font-family: "Gotham HTF";
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;

				&:not(:last-child) {
					margin-bottom: 15px;
				}
			}
			.btn {
				color: $colorWhite;
			}
			&:hover {
				.main-info__box-img {
					img {
						transform: scale(1.05);
					}
				}
			}
		}
	}
	&-product {
		background: $colorBlack;
		position: relative;
		overflow: hidden;
		.container {
			height: 100%;
		}
		&__background {
			img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				min-width: 1920px;
			}
			video {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
				&::-webkit-media-controls-panel {
					display: none !important;
					-webkit-appearance: none;
				}
				&::-webkit-media-controls-play-button {
					display: none !important;
					-webkit-appearance: none;
				}
				&::-webkit-media-controls-start-playback-button {
					display: none !important;
					-webkit-appearance: none;
				}
			}
		}
		&__inner {
			// padding-bottom: 75px;
			height: 100%;
			z-index: 2;
			position: relative;

			display: flex;
			flex-direction: column;
		}
		&__top {
			position: absolute;
			top: 42.95%;
			right: 0;
			transform: translateY(-50%);
			width: 410px;
			.btn {
				// margin-left: 26px;
				color: $colorWhite;
			}
		}
		&__title {
			&:not(:last-child) {
				margin-bottom: 12px;
			}
		}
		&__bottom {
			width: 100%;
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			margin-top: auto;
		}
		&__drones {
			&-title {
				font-family: "Gotham Book", sans-serif;
				color: $colorOrange;
				&:not(:last-child) {
					margin-bottom: 20px;
				}
			}
			&__item {
				&:not(:last-child) {
					margin-bottom: 15px;
				}
				&.animate,
				&:hover {
					.main-product__drones__link {
						color: $colorOrange;
					}
					.main-product__drones__line {
						opacity: 1;
						width: 44px;
					}
				}
			}
			&__link {
				position: relative;
				display: block;
				padding-left: 60px;
				color: $colorWhite;
				text-transform: uppercase;
			}
			&__line {
				opacity: 0;
				content: "";
				position: absolute;
				top: 50%;
				left: 0;
				width: 0;
				height: 1px;
				background: $colorOrange;
				// transform: translateY(-50%) scaleY(0.5);
				transform: scaleY(0.5);
				transition: all 1s;
			}
		}
		&__desc {
			max-width: 410px;
			&-box {
				color: $colorWhite;
			}
		}
	}
	&-benefit {
		background: $colorBlack;
		&__inner {
			display: grid;
			grid-template-columns: minmax(0, 500px) minmax(0, 1073px);
			grid-gap: 0 50px;
			align-items: center;
			justify-content: space-between;
			position: relative;
		}
		&__info {
			&-heading {
				color: $colorGray;
				&:not(:last-child) {
					margin-bottom: 15px;
				}
			}
			&-title {
				&:not(:last-child) {
					margin-bottom: 25px;
				}
			}
			&-text {
				max-width: 410px;
			}
		}
		&__slider {
			position: relative;
			&::before {
				content: "";
				position: absolute;
				left: -1px;
				top: 0;
				bottom: 0;
				background-image: linear-gradient(
					to right,
					#000000 0%,
					rgba(0, 0, 0, 0) 100%
				);
				width: calc(50% - 16px);
				z-index: 1;
				pointer-events: none;
				will-change: transform;
			}
			&:after {
				content: "";
				position: absolute;
				right: -1px;
				top: 0;
				bottom: 0;
				background-image: linear-gradient(
					to left,
					#000000 0%,
					rgba(0, 0, 0, 0) 100%
				);
				width: calc(50% - 16px);
				z-index: 1;
				pointer-events: none;
				will-change: transform;
			}
			&-list {
				overflow: hidden;
				display: flex;
				align-items: center;
			}
			&-box {
				margin: 0 4.6vw;
				transition: all 1s ease;
				position: relative;
				padding-bottom: calc(100% - 9.2vw);
				transform: scale(1);
				img {
					position: absolute;
					top: 0;
					left: 0;
					object-fit: contain;
					object-position: center;
					width: 100%;
					height: 100%;
				}
			}
			.slick-list {
				padding: 0 !important;
				margin: 0 -8.5vw;
			}
			.slick-track {
				display: flex;
				align-items: center;
				flex-wrap: nowrap;
				height: auto;
				justify-content: center;
			}
			.slick-slide {
				float: none;
				display: inline-block;
				vertical-align: middle;
				transition: all 1s ease;
				height: auto;
				text-align: center;
				&.slick-current,
				&.slick-center {
					padding: 30px 0px;
				}
			}
			// ,
			// .slick-slide.slick-center
			.slick-slide.slick-current {
				transform: scale(1.5);
				.main-benefit__slider-box {
					margin: 0 3.1vw;
					padding-bottom: calc(92.51% - 8.2vw);
				}
			}
			.slick {
				&-arrow {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					color: $colorOrange;
					will-change: transform;
				}
				&-prev {
					left: 32px;
				}
				&-next {
					right: 32px;
				}
			}
		}
	}
	&-features {
		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 160px;
			background-image: linear-gradient(
				180deg,
				rgba(0, 0, 0, 0) 0%,
				#000000 100%
			);
			z-index: 1;
			pointer-events: none;
			transform: translate3d(0, 0, 0);
		}
		&__breadcrumbs {
			position: absolute;
			top: 203px;
			left: 68px;
			z-index: 1;
			.breadcrumbs {
				ul {
					li {
						a,
						span {
							font-size: 16px;
						}
					}
				}
			}
		}
		&__slider {
			height: 100%;
			&__list {
				height: 100%;
				.slick {
					&-list {
						height: 100%;
					}
					&-track {
						height: 100%;
					}
				}
			}
			&__arrow {
				.slick {
					&-arrow {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						// color: $colorWhite;
						color: $colorOrange;
						will-change: transform;
					}
					&-prev {
						left: 37px;
					}
					&-next {
						right: 37px;
					}
				}
			}
			&__box {
				position: relative;
				display: flex;
				flex-direction: column;
				padding: 203px 10px 90px;
				height: 100%;
				&-background {
					img, video {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center;
					}
				}
				&-bottom {
					position: relative;
					margin-top: auto;
					width: 100%;
					z-index: 1;
				}
				&-heading {
					font-family: "Gotham HTF";
					color: $colorWhite;
					&:not(:last-child) {
						margin-bottom: 10px;
					}
				}
				&-title {
					text-align: center;
					h2 {
						font-size: 3.125vw;
						font-family: "Gotham HTF XLight";
						font-weight: 300;
					}
				}
			}
		}
	}
	&-video {
		background: $colorBlack;
		&__wrapper {
			height: 100%;
		}
		&__inner {
			height: 100%;
			// padding: 55px 96px;
			&.section-inner {
				padding: {
					left: 97px;
					right: 97px;
				};
			}
			transition: padding 1s;
			&.active {
				padding: 0px;
			}
		}
		.video {
			&-box {
				height: 100%;
				position: relative;
				video {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
		}
	}
	&-footer {
		background: $colorFooterBg;
	}
	&-desc {
		padding: 175px 0 166px;
		&.with-full-page {
			padding: 0;
			.main-desc {
				&__inner {
					display: flex;
					// padding: 160px 0 80px;
				}
			}
			.container {
				// height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
		}
		&__inner {
			display: flex;
			justify-content: flex-end;
		}
		&__heading {
			margin-top: 2px;
			color: $colorOrange;
			&:not(:last-child) {
				margin-right: 153px;
			}
		}
		&__info {
			width: 100%;
			max-width: 849px;
			&-big,
			&-small {
				&:not(:last-child) {
					margin-bottom: 28px;
				}
				p,
				ol,
				ul {
					&:not(:last-child) {
						margin-bottom: 28px;
					}
				}
				a {
					color: #c45a32;
					&:hover {
						color: #c45a32;
					}
				}
				ul {
					li {
						position: relative;
						padding-left: 20px;
						&:not(:last-child) {
							margin-bottom: 1px;
						}
						&:before {
							content: "";
							position: absolute;
							left: 5px;
							top: 12px;
							display: block;
							height: 4px;
							width: 4px;
							border-radius: 50%;
							background: $colorOrange;
						}
					}
				}
				ol {
					counter-reset: section;
					list-style-type: none;
					li {
						position: relative;
						padding-left: 20px;
						&:not(:last-child) {
							margin-bottom: 1px;
						}
						&:before {
							counter-increment: section;
							content: counters(section, ".") ". ";
							position: absolute;
							left: 0px;
							top: 0;
							display: block;
						}
					}
				}
			}
			&-big {
				font-family: "Gotham Book";
				&.v2 {
					font-size: 20px;
					line-height: 29px;
				}
				&:not(:last-child) {
					margin-bottom: 83px;
				}
			}
		}
	}
	&-404 {
		&__inner {
			position: relative;
			min-height: 100%;
			// padding: 78px 96px;
			display: flex;
		}
		&__content {
			position: relative;
			display: flex;
			flex-direction: column;
			min-height: 100%;
			z-index: 110;
			padding-top: 100px;
		}
		&__middle {
			margin: auto 0;
			width: 100%;
			max-width: 560px;
		}
		&__bottom {
			width: 100%;
			max-width: 430px;
			margin-left: 7px;
		}
		&__heading {
			&:not(:last-child) {
				margin-bottom: 18px;
			}
			p {
				&:not(:last-child) {
					margin-bottom: 10px;
				}
			}
			.tnp {
				&:not(:last-child) {
					margin-bottom: 20px;
				}
				form {
					display: flex;
					align-items: flex-end;
					.tnp-field {
						&.tnp-field-email {
							width: 100%;
							&:not(:last-child) {
								margin-right: 10px;
							}
							label {
								color: #c45a32;
							}
							input {
								height: 42px;
								color: $colorGray;
								background: transparent;
								border: none;
								border-bottom: 1px solid $colorGray;
								border-radius: 0;
								box-shadow: none;
								padding-left: 0;
								&:focus {
									border-bottom: 1px solid $colorGray;
								}
							}
						}
						&.tnp-field-button {
							margin-bottom: 0;
							height: 42px;
							input {
								height: 42px;
								color: $colorOrange;
								background: transparent;
								text-transform: uppercase;
								border: none;
							}
						}
					}
				}
			}
		}
		&__info {
			&:not(:last-child) {
				margin-bottom: 40px;
			}
		}
		&__backgrouhnd {
			&__img {
				&-box {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					width: 61%;
					transition: all 1s ease;
					img {
						position: absolute;
						top: 0;
						right: 0;
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center;
						z-index: 103;
					}
				}
			}
			&__block {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				z-index: 105;
				width: 65%;
				border-bottom: 100vh solid $colorBlack;
				border-left: 25.8vw solid transparent;
				transform: rotate(-180deg);
			}
		}
	}
	&-terms {
		&__inner {
			// padding: 160px 0 80px;
			width: 100%;
			max-width: 849px;
			margin: 0 auto;
		}
		&__title {
			text-align: center;
			&:not(:last-child) {
				margin-bottom: 50px;
			}
		}
		&__info {
			p {
				&:not(:last-child) {
					margin-bottom: 28px;
				}
			}
		}
	}
}

.news {
	&-banner {
		position: relative;
		.container {
			height: 100%;
		}
		&__inner {
			position: relative;
			display: flex;
			flex-direction: column;
			height: 100%;
			// padding-bottom: 80px;
			z-index: 2;
		}
		&__bg {
			video {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;

				&::-webkit-media-controls-panel {
					display: none !important;
					-webkit-appearance: none;
				}
				&::-webkit-media-controls-play-button {
					display: none !important;
					-webkit-appearance: none;
				}
				&::-webkit-media-controls-start-playback-button {
					display: none !important;
					-webkit-appearance: none;
				}
			}
		}
		&__content {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: auto;
		}
		&__link {
			display: flex;
			align-items: center;
			&:not(:first-child) {
				margin-left: 99px;
			}
			.btn {
				&:not(:last-child) {
					margin-right: 28px;
				}
			}
		}
		&__control {
			display: flex;
			align-items: center;
			&:not(:first-child) {
				margin-left: -30px;
			}
			&__time {
				min-width: 100px;
				font-family: "Gotham Book";
				text-transform: uppercase;
				font-size: 13px;
				font-weight: 300;
				&:not(:last-child) {
					margin-right: 20px;
				}
			}
			&__progress {
				width: 272px;
				border-radius: 80.8863px;
				overflow: hidden;
				position: relative;
				height: 10px;
				display: flex;
				align-items: center;
				&-total {
					width: 100%;
					height: 1px;
					background: rgba($colorOrange, 0.5);
					z-index: 2;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
				}
				&-current {
					background: $colorOrange;
					line-height: 0;
					height: 3px;
					border-radius: 80px;
					width: 0;
					position: absolute;
					top: 50%;
					bottom: 0;
					left: 0;
					transform: translateY(-50%);
					z-index: 3;
					transition: width 0.25s;
				}
			}
		}
		&__more {
			&:not(:first-child) {
				margin-right: 29px;
			}
		}
		&__info {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&__heading {
			&:not(:last-child) {
				margin-bottom: 15px;
			}
		}
		&__title {
			width: 100%;
			max-width: 600px;
		}
	}
	&-slider {
		&__inner {
			// padding: 160px 0 80px;
		}
		&__top {
			display: flex;
			align-items: center;

			&:not(:last-child) {
				margin-bottom: 53px;
			}
		}
		&__counter {
			display: flex;
			align-items: center;
			font-size: 13px;
			.icon {
				font-size: 13px;
				line-height: 1;
				font-weight: 600;
				color: $colorOrange;

				margin-left: 6px;
				margin-right: 1px;
			}
			&:not(:last-child) {
				margin-right: 40px;
			}
		}
		&__arrow {
			display: flex;
			align-items: center;
			.slick {
				&-arrow {
					color: $colorOrange;
					&:not(:last-child) {
						margin-right: 15px;
					}
				}
			}
		}
		&__box {
			position: relative;
			margin: 0 14px;
			&-img {
				position: relative;
				padding-bottom: 67.92%;
				&:not(:last-child) {
					margin-bottom: 34px;
				}
				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
			&-text {
				width: 100%;
				max-width: 556px;
				color: $colorGray;
			}
		}
		.slick {
			&-list {
				margin: 0 -14px 0 -93px;
				padding-left: 81px;
			}
		}
	}
	&-list {
		&__inner {
			// padding: 206px 0 127px;
		}
		// padding: 197px 0 130px;
		&__top {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		&__top {
			&:not(:last-child) {
				margin-bottom: 55px;
			}
		}
		&__content {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 58px 28px;
			&.v2 {
				grid-template-columns: 1fr;
				grid-gap: 104px 0;
			}
		}
	}
	&-box {
		width: 100%;
		max-width: 1444px;
		display: grid;
		grid-template-columns: 5fr 4fr;
		grid-gap: 0 176px;
		align-items: center;
		&__content {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}
		&__img {
			position: relative;
			padding-bottom: 56%;
			width: 100%;
			overflow: hidden;
			z-index: 1;
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;

				transition: transform 1.5s;
			}
		}
		&__title {
			&:not(:last-child) {
				margin-bottom: 24px;
			}
		}
		&__text {
			color: $colorBlack;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			&:not(:last-child) {
				margin-bottom: 13px;
			}
		}
		&:hover {
			.news-box__img {
				img {
					transform: scale(1.05);
				}
			}
		}
	}
	&-desc {
		&__inner {
			display: flex;
			flex-direction: column;
		}
		&__row {
			display: flex;
			align-items: center;
			margin: 0 auto;

			&.center {
				margin: auto auto !important;
			}
			&:not(:last-child) {
				margin-bottom: 126px;
			}
		}
		&__block {
			max-width: 848px;
			flex: 1 0 0;
			&:not(:last-child) {
				margin-right: 87px;
				// 28
			}
			&:first-child {
				max-width: 789px;
			}
		}
		&__img {
			// flex: 1 0 0;
			// max-width: 848px;
			display: flex;
			justify-content: flex-start;
			&:last-child {
				justify-content: flex-end;
			}

			&-small {
				max-width: 702px;
				width: 100%;
			}
			img, video {
				max-width: 100%;
			}
			.video-box {
				position: relative;
			}
		}
		&__content {
			// flex: 1 0 0;
			max-width: 790px;
			&.full-width {
				max-width: 100%;
			}
		}
		&__heading {
			color: $colorOrange;
			&:not(:last-child){ 
				margin-bottom: 29px;
			}
		}
		&__text {
			font-size: 16px;
			line-height: 1.6875;
			font-weight: 300;

			color: $colorAlabaster;
			&-big {
				font-size: 25px;
				line-height: 1.36;
			}

			&-column {
				column-count: 2;
				column-gap: 87px;
				max-width: 1667px;	
			}


			p, ul, ol {
				&:not(:last-child) {
					margin-bottom: 27px;
				}
			}
			a {
				color: #c45a32;
				&:hover {
					color: #c45a32;
				}
			}
			ul {
				li {
					position: relative;
					padding-left: 20px;
					&:not(:last-child) {
						margin-bottom: 1px;
					}
					&:before {
						content: "";
						position: absolute;
						left: 5px;
						top: 12px;
						display: block;
						height: 4px;
						width: 4px;
						border-radius: 50%;
						background: $colorOrange;
					}
				}
			}
			ol {
				counter-reset: section;
				list-style-type: none;
				li {
					position: relative;
					padding-left: 20px;
					&:not(:last-child) {
						margin-bottom: 1px;
					}
					&:before {
						counter-increment: section;
						content: counters(section, ".") ". ";
						position: absolute;
						left: 0px;
						top: 0;
						display: block;
					}
				}
			}
		}
		&__quote {
			margin: 0 auto;
			width: 100%;
			max-width: 556px;

			display: flex;
			flex-direction: column;
			align-items: center;

			text-align: center;
			&:not(:first-child) {
				margin-top: 100px;
			}
			&:not(:last-child) {
				margin-bottom: 240px;
			}
			&-img {
				max-width: 24px;
				width: 100%;
				&:not(:last-child) {
					margin-bottom: 48px;
				}
				img, video {
					width: 100%;
				}
			}
			&-text {
				font-family: "Gotham Book";
				font-size: 25px;
				line-height: 1.36;
				font-weight: 300;
				color: $colorOrange;
				&:not(:last-child) {
					margin-bottom: 45px;
				}
			}
			&-hero {
				font-family: "Gotham Book";
				font-weight: 300;
				font-size: 13px;
				line-height: 1.461;
				color: $colorAlabaster;
				text-transform: uppercase;

			}
		}
		&__btn {    
			margin-top: 62px;
			margin-left: auto;
		}
	}
}

.product {
	&-page {
		&__toggle-theme {
			position: fixed;
			left: 96px;
			top: 45.3%;
			transform: translateY(-50%);
			z-index: 6;
			opacity: 1;
			transition: all 2s;
		}
		&__back {
			position: fixed;
			right: 96px;
			top: 156px;
			width: 419px;
			right: 96px;
			z-index: 6;
			transition: all 0.5s;
			font-family: "Gotham HTF Book";
		}
		&__points {
			display: none;
			&-item {
				position: fixed;
				z-index: 2;
			}
		}
	}
	&-info {
		position: relative;
		background: $colorBlack;
		overflow: hidden;
		.container {
			height: 100%;
		}
		&__inner {
			height: 100%;
			display: flex;
			flex-direction: column;

			&.section-inner {
				padding: {
					top: 0;
					bottom: 0;
				};
			}
		}
		&__block {
			height: 100%;
			min-height: 100%;
			display: flex;
			flex-direction: column;
			// padding-bottom: 75px;
			// min-height: calc(100vw * 0.5625);
			padding: 90px 0;

			&__range {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
				transform: translate3d(0, 0, 0);
				z-index: 1111;
				pointer-events: none;
				[data-theme-white-opacity],
				[data-theme-dark-opacity] {
					transition: opacity 1s;
				}
			}
		}
		&__bottom {
			margin-top: auto;
			display: flex;
			justify-content: space-between;
			align-items: flex-end;

			position: relative;
			z-index: 4;
			pointer-events: none;

			&.v2 {
				transition: opacity 1s;
			}

			&__head {
				width: 100%;
				max-width: 620px;
			}
			&__body {
				width: 100%;
				display: flex;
				align-items: flex-end;
				justify-content: flex-end;
				padding-bottom: 10px;
			}
			&__text {
				width: 100%;
				max-width: 420px;
				&:not(:last-child) {
					margin-bottom: 20px;
				}
			}
		}
		&__heading {
			&:not(:last-child) {
				margin-bottom: 13px;
			}
		}
		&__title {
			h2 {
				font-weight: 300;
				font-family: "Gotham Extra";
			}
		}
		&__hint {
			position: absolute;
			top: 45%;
			right: 87px;
			transform: translateY(-50%);
			width: 421px;
			z-index: 5;
			color: $colorGray;
			font-family: "Gotham Book";
			font-weight: 300;
			text-transform: uppercase;
		}
		&__details {
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			height: 100%;

			z-index: 3;
			&-img {
				&__block {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 100%;
					z-index: 3;
					width: 100%;
					// position: relative;
				}
				img {
					width: 100%;
				}
			}
			&__points {
				&-item {
					position: absolute;
					&.active {
						.product-info__details__points {
							&-pin {
								width: 35px !important;
								height: 35px !important;
							}
							&-hint {
								opacity: 1;
								&.show {
									&-left {
										transform: translate(-10px, -50%);
									}
									&-right {
										transform: translate(10px, -50%);
									}
								}
							}
						}
					}
				}
				&-pin {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);

					display: flex;
					justify-content: center;
					align-items: center;
					// background-color: rgba(29, 29, 29, 0.83);
					// border: 2px solid $colorOrange;
					// color: $colorOrange;

					width: 29px;
					height: 29px;
					border-radius: 50%;

					font-size: 18px;
					line-height: 1;

					z-index: 2;

					transition: all 0.5s, background-color 1s;
					// &:hover {
					// 	color: $colorOrange;
					// }
				}
				&-hint {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					opacity: 0;
					pointer-events: none;
					color: $colorGray;
					max-width: 300px;
					width: max-content;
					font-weight: 300;
					transition: opacity 0.5s, transform 0.5s;
					&.show-left {
						right: 25px;
						// opacity: 1;
					}
					&.show-right {
						left: 25px;
						// opacity: 1;
					}
				}
			}
		}
		&__video {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 3;
			width: 100%;
			&-container {
				display: flex;
			}
			&-normal, &-reverse {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 3;
				width: 100%;
			}
			video {
				width: 100%;

				&::-webkit-media-controls-panel {
					display: none !important;
					-webkit-appearance: none;
				}
				&::-webkit-media-controls-play-button {
					display: none !important;
					-webkit-appearance: none;
				}
				&::-webkit-media-controls-start-playback-button {
					display: none !important;
					-webkit-appearance: none;
				}
			}
		}
		&__characteristics {
			&:not(:last-child) {
				margin-right: 50px;
			}
			&__box {
				display: flex;
				flex-direction: column;
				align-items: center;
				text-align: center;
				max-width: 125px;
				&:not(:last-child) {
					margin-right: 30px;
				}
				&__top {
					display: flex;
					align-items: flex-end;
					&:not(:last-child) {
						margin-bottom: 15px;
					}
				}
				&__sup {
					align-self: flex-start;
					// margin-top: 9px;
					margin-right: 9px;
				}
				&__sub {
					// margin-bottom: 12px;
				}
				&-title {
					margin-right: 6px;
					h2 {
						font-weight: 300;
						font-family: "Gotham HTF XLight";
						font-size: 50px;
						line-height: 1;
					}
				}
				&-text {
					font-family: "Gotham Book";
					text-transform: uppercase;
					color: $colorGray;
					// font-size: 13px;
					font-size: 12px;
				}
			}
		}
		&__range {
			position: absolute;
			top: 46.8%;
			right: 96px;
			transform: translateY(-50%);
			z-index: 10;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			transition: opacity 1s;
			pointer-events: all;
			&-box {
				padding-left: 15px;
				&:not(:last-child) {
					margin-bottom: 15px;
				}
			}
			&__title {
				font-family: "Gotham Book";
				font-size: 13px;
				text-transform: uppercase;
				color: $colorCodGray;
				&:not(:last-child) {
					margin-right: 96px;
				}
			}
			&__input {
				position: relative;
				width: 100%;
				max-width: 297px;
			}
			&__info {
				&__item {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					text-align: center;
					height: 16px;
					background: $colorOrange;
					width: 1px;
				}
				&__text {
					position: absolute;
					bottom: calc(100% + 18px);
					left: 50%;
					transform: translateX(-50%);

					font-family: "Gotham HTF";
					font-size: 10px;
				}
			}
			&__hint {
				position: absolute;
				top: calc(100% + 11px);
				right: 0;

				font-family: "Gotham HTF";
				font-size: 10px;
			}
		}
	}
	&-characteristics {
		&__inner {
			// padding: 160px 0;
		}
		&__top {
			&:not(:last-child) {
				margin-bottom: 82px;
			}
		}
		&__list {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			max-width: 1024px;
			width: 100%;
			margin: 0 auto;
			grid-gap: 102px 50px;
			justify-content: space-between;
		}
		&__box {
			display: flex;
			flex-direction: column;
			align-items: center;
			&__top {
				display: flex;
				align-items: flex-end;
				&:not(:last-child) {
					margin-bottom: 15px;
				}
				.product-characteristics__box-text {
					font-size: 16px;
				}
			}
			&__sup {
				align-self: flex-start;
				margin-right: 12px;
				margin-top: 5px;
			}
			&__sub {
				margin-bottom: 3px;
			}
			&-title {
				margin-right: 6px;
				h2 {
					font-family: "Gotham HTF XLight";
					font-size: 75px;
					line-height: 1;
				}
			}
			&-text {
				color: $colorGray;
				font-size: 12px;
				line-height: 1.6875;
			}
		}
	}
}

.contact {
	&-banner {
		// min-height: 100vh;
		background-color: $colorContactBg;
		&__inner {
			display: flex;
			flex-direction: column;
			// padding: 124px 0 87px;
			min-height: 100vh;
			justify-content: center;
		}
		&__top {
			margin: auto 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		&__info {
			min-width: 600px;
			&:not(:last-child) {
				margin-right: 62px;
				margin-bottom: 30px;
			}
			&-heading {
				color: $colorAlabaster;
				&:not(:last-child) {
					margin-bottom: 17px;
				}
			}
		}
		&__map {
			position: relative;
			width: 100%;
			&-img {
				width: 100%;
			}
			&-points {
				a {
					position: absolute;
					z-index: 20;

					font-size: 22px;
					line-height: 1;
					color: $colorOrange;
					&:hover {
						color: $colorOrange;
					}

					.icon {
						position: absolute;
						left: 50%;
						transform: translateX(-50%);
						bottom: 0;
					}
				}
			}
			&-sections {
				img {
					position: absolute;
					width: 100%;
					z-index: 10;
					top: 0;
					left: 0;
					transition: opacity 0.5s;
				}
			}
			&-hint {
				display: none;
				position: absolute;
				left: 220px;
				top: calc(100% + 100px);

				font-family: "Gotham Book";
				font-size: 13px;
				text-transform: uppercase;

				color: $colorGray;
			}
		}
		&__bottom {
			margin-top: 20px;
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 30px;
		}
		&__hint {
			grid-column-start: 2;
			grid-column-end: 3;

			font-family: "Gotham Book";
			font-size: 13px;
			color: $colorGray;

			text-transform: uppercase;
			// font-size: 10px;
		}
	}
	&-info {
		// min-height: 100vh;

		background: $colorBlack;
		.container {
			height: 100%;
		}
		&__inner {
			height: 100%;
			display: flex;
			flex-direction: column;

			// padding-bottom: 84px;
		}
		&__top {
			padding: 100px 0 0 0;
			margin: auto 0;

			display: grid;
			// grid-template-columns: minmax(0, 500px) minmax(0, 1073px);
			grid-template-columns: 1fr; // remove if need slider
			grid-gap: 0 50px;
			align-items: center;
			justify-content: space-between;
			position: relative;
		}
		&__bottom {
			display: grid;
			// grid-template-columns: 1fr 1fr;
			grid-template-columns: 1fr;
			grid-gap: 0 50px;
			align-items: center;
			position: relative;
		}
		&__list {
			display: grid;
			grid-template-columns: 1fr;
			grid-gap: 50px;
		}
		&__item {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 30px;
			align-items: center;
			width: 100%;
		}
		&__slider {
			position: relative;
			&::before {
				content: "";
				position: absolute;
				left: -2px;
				top: 0;
				bottom: 0;
				background-image: linear-gradient(
					to right,
					#000000 0%,
					rgba(0, 0, 0, 0) 100%
				);
				width: calc(50% - 16px);
				z-index: 1;
			}
			&:after {
				content: "";
				position: absolute;
				right: -2px;
				top: 0;
				bottom: 0;
				background-image: linear-gradient(
					to left,
					#000000 0%,
					rgba(0, 0, 0, 0) 100%
				);
				width: calc(50% - 16px);
				z-index: 1;
			}
			&-list {
				overflow: hidden;
			}
			&-box {
				margin: 0 4.6vw;
				// margin: 0;
				transition: all 1s ease;

				position: relative;
				padding-bottom: calc(74.8% - 9.2vw);
				transform: scale(1);
				img {
					position: absolute;
					top: 0;
					left: 0;
					object-fit: cover;
					object-position: center;
					width: 100%;
					height: 100%;
				}
			}
			// flex: 1 0 0;
			.slick-list {
				padding: 0 !important;
				// margin: 0 -8.5vw;
				margin: 0 -15.5vw;
			}
			.slick-track {
				display: flex;
				align-items: center;
				flex-wrap: nowrap;
				height: auto;
				justify-content: center;
			}
			.slick-slide {
				float: none;
				display: inline-block;
				vertical-align: middle;
				transition: all 1s ease;
				height: auto;
				text-align: center;
				padding: 30px 0px;
				&.slick-current,
				&.slick-center {
					padding: 30px 0px;
				}
			}
			.slick-slide.slick-current,
			.slick-slide.slick-center {
				transition: all 1s;
				transform: scale(1.11);
				.contact-info__slider-box {
					margin: 0;
					transform: scale(1);
					padding-bottom: 74.8%;
				}
			}
			.slick {
				&-arrow {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					color: $colorOrange;
				}
				&-prev {
					left: 32px;
				}
				&-next {
					right: 32px;
				}
			}
		}
		&__desc {
			&-heading {
				&:not(:last-child) {
					margin-bottom: 15px;
				}
			}
		}
		&__data {
			display: flex;
			&-box {
				position: relative;
				width: 100%;
				max-width: 200px;
				padding-left: 23px;
				&:before {
					content: "\EA08";
					position: absolute;
					// left: -23px;
					left: 0;
					top: 0;

					font-family: "svgfont";
					font-size: 15px;
					font-weight: 600;

					color: $colorOrange;
				}

				a {
					display: block;
					color: $colorAlabaster;
					transition: opacity 0.5s;
					&:hover {
						opacity: 0.6;
						color: $colorAlabaster;
					}
				}
			}
		}
	}
}

.video {
	&__play {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		align-items: center;
		color: $colorOrange;
		cursor: pointer;
		transition: all 2s ease;
		.icon {
			font-size: 31px;
			&:not(:last-child) {
				margin-right: 20px;
			}
		}
		span {
			font-family: "Gotham Book", sans-serif;
			text-transform: uppercase;
			font-size: 20px;
			font-weight: 300;
			text-shadow: 0 0 5px rgba(210, 109, 59, 0.49);
		}
		&.hidden {
			opacity: 0;
			pointer-events: none;
		}
	}
	&__sound {
		position: absolute;
		bottom: 40px;
		left: 40px;

		cursor: pointer;

		transition: opacity .5s;

		.icon {
			font-size: 26px;
			line-height: 1;
	
			color: $colorOrange;
		}
	}
}

.breadcrumbs {
	transform: translate3d(0, 0, 0);
	&.v2 {
		margin-left: -21px;
	}
	ul {
		display: flex;
		flex-wrap: wrap;
		li {
			a,
			span {
				position: relative;
				display: block;
				min-width: max-content;
				padding-left: 21px;
				text-transform: uppercase;
				color: $colorWhite;
				font-family: "Gotham Book", sans-serif;
				font-size: 13px;
				line-height: 19px;
				&:hover {
					color: $colorWhite;
				}
				&:before {
					content: "\EA08";
					font-family: "svgfont";
					font-size: 17px;
					line-height: 1;
					font-weight: 600;
					color: $colorOrange;

					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
				}
				&:not(:last-child) {
					margin-right: 12px;
				}
			}
		}
	}
	&.black {
		ul {
			li {
				a,
				span {
					color: $colorCodGray;
					&:hover {
						color: $colorCodGray;
					}
				}
			}
		}
	}
}

.switch {
	position: relative;
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	&-slider {
		width: 27px;
		height: 50px;
		position: relative;
		cursor: pointer;
		border-radius: 20px;
		background-color: transparent;
		border: 2px solid $colorGray;
		-webkit-transition: 0.4s;
		transition: 0.4s;
		&:before {
			position: absolute;
			content: "";
			height: 19px;
			width: 19px;
			left: 2px;
			top: 4px;
			background-color: $colorGray;
			-webkit-transition: 0.4s;
			transition: 0.4s;
			border-radius: 50%;
		}
		&:not(:last-child) {
			margin-right: 10px;
		}

		&:hover {
			~ .switch-hint {
				opacity: 1;
				transform: translateX(10px);
			}
		}
	}
	&-hint {
		opacity: 0;
		transition: opacity 1s, transform 1s;
	}
	&-text {
		color: $colorGray;
		font-size: 13px;
		text-transform: uppercase;
	}
	input {
		opacity: 0;
		width: 0;
		height: 0;
		&:checked + .switch-slider {
			&:before {
				-webkit-transform: translateY(21px);
				-ms-transform: translateY(21px);
				transform: translateY(21px);
			}
		}
	}
}

[data-theme-white-opacity],
[data-theme-dark-opacity] {
	transition: opacity 1s;
}

.variant-bg-white {
	background: $colorAlto !important;
	.product {
		// &-info {
		// 	&__details {
		// 		&__points {
		// 			&-pin {
		// 				background-color: rgba(224, 224, 224, 0.83);
		// 			}
		// 		}
		// 	}
		// }
		&-page {
			&__points {
				&-item {
					background-color: rgba(224, 224, 224, 0.83);
				}
			}
		}
	}

	.main {
		&-benefit {
			&__slider {
				&::before {
					background-image: linear-gradient(
						to right,
						$colorAlto 0%,
						rgba(224, 224, 224, 0) 100%
					);
				}

				&::after {
					background-image: linear-gradient(
						to left,
						$colorAlto 0%,
						rgba(224, 224, 224, 0) 100%
					);
				}
			}
		}
	}

	&.gradient-bg,
	.gradient-bg {
		&:before,
		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			width: 100%;
			height: 100px;
			z-index: 1;
			pointer-events: none;
		}
		&:before {
			top: -1px;
			background: linear-gradient(
				to top,
				rgba(255, 255, 255, 0),
				$colorAlto
			);
		}
		&:after {
			bottom: -1px;
			background: linear-gradient(
				to bottom,
				rgba(255, 255, 255, 0),
				$colorAlto
			);
		}
	}
}

.variant-bg-dark {
	background: $colorBlack !important;

	.product {
		// &-info {
		// 	&__details {
		// 		&__points {
		// 			&-pin {
		// 				background-color: rgba(29, 29, 29, 0.83);
		// 			}
		// 		}
		// 	}
		// }
		&-page {
			&__points {
				&-item {
					background-color: rgba(29, 29, 29, 0.83);
				}
			}
		}
	}

	.main {
		&-benefit {
			&__slider {
				&::before {
					background-image: linear-gradient(
						to right,
						$colorBlack 0%,
						rgba(0, 0, 0, 0) 100%
					);
				}

				&::after {
					background-image: linear-gradient(
						to left,
						$colorBlack 0%,
						rgba(0, 0, 0, 0) 100%
					);
				}
			}
		}
	}

	&.gradient-bg,
	.gradient-bg {
		&:before,
		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			width: 100%;
			height: 100px;
			z-index: 1;
			pointer-events: none;
		}
		&:before {
			top: -1px;
			background: linear-gradient(to top, rgba(0, 0, 0, 0), $colorBlack);
		}
		&:after {
			bottom: -1px;
			background: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0),
				$colorBlack
			);
		}
	}
}

.variant-bg-gray {
	background: $colorCodGray !important;

	.product {
		// &-info {
		// 	&__details {
		// 		&__points {
		// 			&-pin {
		// 				background-color: rgba(29, 29, 29, 0.83);
		// 			}
		// 		}
		// 	}
		// }
		&-page {
			&__points {
				&-item {
					background-color: rgba(29, 29, 29, 0.83);
				}
			}
		}
	}

	.main {
		&-benefit {
			&__slider {
				&::before {
					background-image: linear-gradient(
						to right,
						$colorCodGray 0%,
						rgba(0, 0, 0, 0) 100%
					);
				}

				&::after {
					background-image: linear-gradient(
						to left,
						$colorCodGray 0%,
						rgba(0, 0, 0, 0) 100%
					);
				}
			}
		}
	}

	&.gradient-bg,
	.gradient-bg {
		&:before,
		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			width: 100%;
			height: 100px;
			z-index: 1;
			pointer-events: none;
		}
		&:before {
			top: -1px;
			background: linear-gradient(
				to top,
				rgba(0, 0, 0, 0),
				$colorCodGray
			);
		}
		&:after {
			bottom: -1px;
			background: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0),
				$colorCodGray
			);
		}
	}
}

.variant-text-dark {
	color: $colorCodGray;
	.btn,
	&.btn {
		color: $colorCodGray;
		&:hover,
		&:active {
			color: $colorCodGray;
		}
	}
	.main {
		&-benefit__info-text {
			color: $colorBlack;
		}
		&-product {
			&__drones {
				&__item {
					.main-product__drones__link {
						color: $colorCodGray;
					}
					&.animate,
					&:hover {
						.main-product__drones__link {
							color: $colorOrange;
						}
					}
				}
			}
			&__desc-box {
				color: $colorCodGray;
			}
		}
	}
	.product {
		&-info {
			&__range__title {
				color: $colorCodGray;
			}
		}
	}
	.breadcrumbs {
		ul {
			li {
				a,
				span {
					color: $colorCodGray !important;
				}
			}
		}
	}
	.product-info__characteristics__box {
		&-text {
			color: $colorCodGray;
		}
	}
}

.variant-text-white {
	color: $colorAlabaster;
	.btn,
	&.btn {
		color: $colorAlabaster;
		&:hover,
		&:active {
			color: $colorAlabaster;
		}
	}
	.main-product {
		&__drones {
			&__item {
				.main-product__drones__link {
					color: $colorAlabaster;
				}
				&.animate,
				&:hover {
					.main-product__drones__link {
						color: $colorOrange;
					}
				}
			}
		}

		&__desc-box {
			color: $colorAlabaster;
		}
	}
	.product-info__range__title {
		color: $colorAlabaster;
	}

	.breadcrumbs {
		ul {
			li {
				a,
				span {
					color: $colorAlabaster !important;
				}
			}
		}
	}
	.product-info__characteristics__box {
		&-text {
			color: $colorAlabaster;
		}
	}
}

#wrap-texture {
	display: flex;
	justify-content: center;
	align-items: center;

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

#canvas {
	/* make the canvas wrapper fits the document */
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.plane {
	/* define the size of your plane */
	width: 100%;
	height: 100%;

	z-index: 1;
}

.plane img {
	/* hide the img element */
	display: none;
}
