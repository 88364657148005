.header {
	// position: fixed;
	// top: 65px;
	// width: 100%;
	z-index: 100;
	opacity: 1;
	transition: all 2s;
	pointer-events: all;

	&-gradient {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		height: 340px;
		transform: translate3d(0, -100px, 0);
		z-index: 1;
		pointer-events: none;
		opacity: 1;
		transition: all 1s linear;
		will-change: opacity;
		svg {
			width: 100%;
			height: 100%;
		}

		.header-gradient-rect {
			fill: url('#Lg');
		  }
		.header-gradient-rect ~ defs stop {
			transition: 2s;
		}

		&.style-white {
			.header-gradient-rect ~ defs stop {
				stop-color: #e0e0e0;
			}
		}
		&.style-gray {
			.header-gradient-rect ~ defs stop {
				stop-color: #1d1d1d;
			}
		}
		&.style-dark {
			.header-gradient-rect ~ defs stop {
				stop-color: #000000;
			}
		}
	}

	&.header-hidden {
		pointer-events: none;
		opacity: 0;
	}
	&__inner {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&-logo {
		width: 65px;
		position: fixed;
		top: 65px;
		left: 96px;
		z-index: 100;
		transition: all 2s;
		will-change: opacity;
	}	
	&-toggle, &-back {
		position: fixed;
		top: 86px;
		right: 96px;
		z-index: 100;

		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		font-size: 26px;
		line-height: 1;
		color: $colorOrange;
		transition: all 2s;
		will-change: opacity;
	}
	&-menu {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 101;
		opacity: 0;
		pointer-events: none;
		&.active {
			opacity: 1;
			pointer-events: all;
		}
		&__content {
			margin-left: auto;
			margin-right: 96px;
			// padding-top: 84px;
			// padding-bottom: 89px;
			padding: {
				top: 90px;
				bottom: 90px;
			};
			height: 100%;

			width: 596px;

			display: flex;
			flex-direction: column;
			justify-content: space-between;
			&__back {
				position: relative;
				z-index: 200;
				margin-left: auto;
				.btn {
					font-family: "Gotham HTF Book";
					.icon {
						color: $colorOrange;
					}
				}
			}
			&__nav {
				padding: 60px 0 60px 39px;
				overflow: auto;
				position: relative;
				z-index: 200;
				&::-webkit-scrollbar {
					width: 0px;
					background: transparent;
				}
				-ms-overflow-style: none;
				scrollbar-width: none;
				overflow: -moz-scrollbars-none;
				ul {
					li {
						&:not(:last-child) {
							margin-bottom: 48px;
						}
						a {
							display: block;
							position: relative;
							color: $colorGray;
							font-size: 40px;
							line-height: 1.2;
							font-family: 'Gotham Extra';
							font-weight: 300;
							font-style: normal;
							letter-spacing: normal;
							text-transform: uppercase;
							span {
								transition: color 1s;
							}
							& .header-menu__content__nav-line {
								position: absolute;
								left: -32px;
								top: 50%;
								transform: translateY(-50%) rotate(25deg);
								
								opacity: 1;

								content: "";
								width: 1px;
								
								height: 35px;
								
								&:before {    content: "";
									display: block;
									background: #c45a32;
									position: absolute;
									left: 0;
									right: 0;
									top: 0;
									bottom: 0;
									height: 0;
									background: $colorOrange;
									transition: height 1s;
								}
							}
							&:hover {
								color: $colorOrange;
								& .header-menu__content__nav-line {
									&:before {
										height: 100%;
									}
								}
							}
						}
						&.active {
							a {
								color: $colorOrange;
								&::before {
									opacity: 1;
								}
								& .header-menu__content__nav-line {
									&:before {
										height: 100%;
									}
								}
							}
						}
					}
				}
			}
			&__info {
				padding-left: 39px;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			&__terms {
				position: relative;
				z-index: 200;
				color: $colorOrange;
				display: flex;
				font-size: 13px;
				ul {
					display: flex;
					align-items: center;
					li {
						position: relative;
						&:not(:last-child) {
							margin-right: 5px;
							&:after {
								content: ',';
								position: absolute;
								left: 100%;
								bottom: 0;
							}
						}
						a {
							display: block;
							color: $colorOrange;
							text-transform: uppercase;
							font-weight: 300;
							font-family: 'Gotham Book';
							transition: opacity 0.5s;
							&:hover {
								color: $colorOrange;
								// text-decoration: underline;
								opacity: 0.6;
							}
						}
					}
				}
			}
			&__soc {
				display: flex;
				align-items: center;
				position: relative;
				z-index: 200;
				li {
					a {
						display: flex;
						color: $colorWhite;
						font-size: 15px;
						transition: opacity 0.5s;
						&:hover {
							color: $colorWhite;
							opacity: 0.6;
						}
					}
					&:not(:last-child) {
						margin-right: 23px;
					}
				}
			}
		}
		&__logo {
			position: fixed;
			top: 65px;
			left: 96px;
			z-index: 110;
			width: 65px;
		}
		&__backgrouhnd {
			&__img {
				&-box {
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					width: 65.7%;

					background: transparent;
					transition: background 0.5s;
					img {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center;
						z-index: 103;
						transform: scale(1);
						opacity: 0;
						transition: all 2s ease;
					}
					&.active {
						z-index: 105;
						background: $colorFooterBg;
						img {
							opacity: 1;
							transform: scale(1.1);
						}
					}
					&.prev {
						z-index: 104;
						img {
							transform: scale(1);
							opacity: 0;
						}
					}
				}
			}
			&__block {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: 105;
				width: 60.7%;
				border-bottom: 100vh solid $colorFooterBg;
				border-left: 25.8vw solid transparent;
			}
			&__opacity {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 102;
				background: $colorFooterBg;
				opacity: 0;
			}
		}
	}
}
