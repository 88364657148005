::-webkit-input-placeholder {
	color: $colorLightGrey;
	opacity: 1;
}
::-moz-placeholder {
	color: $colorLightGrey;
	opacity: 1;
}
:-ms-input-placeholder {
	color: $colorLightGrey;
	opacity: 1;
}
:-moz-placeholder {
	color: $colorLightGrey;
	opacity: 1;
}
input,
textarea {
	position: relative;
	background: $colorWhite;
	color: $colorBlack;
	width: 100%;
	font-size: 14px;
	// font-family: 'Roboto', sans-serif;
  	border: 1px solid $colorGray;
	transition: all 0.3s ease;
	padding: 0 20px;
	appearance: none;
	&:hover {
		border-color: $colorDarkGrey;
	}
	&:active,
	&:focus{
    &:not(.btn){
      &:not([disabled]){
        &::-webkit-input-placeholder {
          color: transparent;
        }
        &::-moz-placeholder {
          color: transparent;
        }
        &:-ms-input-placeholder {
          color: transparent;
        }
        &:-moz-placeholder {
          color: transparent;
        }
      }
    }
	}
	&[disabled] {
		opacity: 0.5;
	}
}
input{
	height: 44px;
	text-overflow: ellipsis;
	overflow: hidden;
	&.error {
		border-color: #ff3f24 !important;
		color: #ff3f24 !important;
	}
}
textarea{
	height: 110px;
	padding: 15px 15px;
	resize: none;
}
.has{
	&-error{
		input,
		textarea{
			color: $colorRed !important;
			border-color: $colorLightRed !important;
			&::-webkit-input-placeholder {
				color: $colorLightRed !important;
			}
			&::-moz-placeholder {
				color: $colorLightRed !important;
			}
			&:-ms-input-placeholder {
				color: $colorLightRed !important;
			}
			&:-moz-placeholder {
				color: $colorLightRed !important;
			}
		}
	}

	&-success {
		input,
		textarea {
			color: $colorGreen !important;
			border-color: $colorGreen !important;
		}
	}
}
.form{
	&-title{
		position: relative;
	}
	&-body{
		position: relative;
	}
	&-text{
		position: relative;
	}
	&-group{
		position: relative;
		&:not(:last-child){
			margin-bottom: 20px;
		}
	}
	&-button{
		position: relative;
		&:not(:last-child){
			margin-bottom: 20px;
		}
	}
}
.checkbox{
	position: relative;
	display: inline-block;
	[type="checkbox"],
	[type="radio"]{
		position: absolute;
		left: -9999px;
		width: inherit;
		height: inherit;
		& ~ label{
			position: relative;
			display: inline-block;
			min-height: 20px;
			color: #1a1a1a;
			line-height: 20px;
			padding-left: 20px;
			margin: -5px 0;
			cursor: pointer;
			&:before{
				content: '';
				position: absolute;
				background: $colorWhite;
				top: 50%;
				left: 0;
				width: 20px;
				height: 20px;
				border: 1px solid #c8c8c8;
				border-radius: 0;
				transform: translateY(-50%);
				transition: all 0.3s ease;
			}
			&:after{
				content: '';
				position: absolute;
				background: #ccc;
				top: 50%;
				left: 4px;
				width: 12px;
				height: 12px;
				transform: translateY(-50%);
				transition: all 0.3s ease;
				opacity: 0;
			}
			&:hover{
				&:before{
					border-color: #999;
				}
			}
			&:not(:empty){
				margin: 0;
				padding-left: 30px;
			}
		}
		&:disabled{
			& ~ label{
				&:before{

				}
				&:after{
					opacity: 0;
				}
			}
		}
		&:checked{
			& ~ label{
				&:before{

				}
				&:after{
					opacity: 1;
				}
			}
			&:disabled{
				& ~ label{
					&:before{

					}
					&:after{
						opacity: 0.2;
					}
				}
			}
		}
	}
	[type="radio"]{
		& ~ label{
			&:before,
			&:after{
				border-radius: 50%;
			}
		}
		&:checked{
			& ~ label{
				&:before{

				}
		  }
		}
	}
	&.has{
		&-error{
			[type="checkbox"],
			[type="radio"]{
				& ~ label{
					color: $colorRed !important;
					&:before{
						border-color: $colorLightRed !important;
					}
				}
			}
		}
	}
}
.help-block{
	position: absolute;
	top: 100%;
	width: 100%;
	color: $colorRed;
	font-size: 12px;
	line-height: 16px;
	padding: 0 30px;
}
