$colorWhite: #fff;
$colorBlack: #000;
$colorLightGrey: #999;
$colorDarkGrey: #333;
$colorGreen: #4ab020;
$colorLightGreen: #99c71f;
$colorDarkGreen: #648300;
$colorRed: #b01e1e;
$colorLightRed: #ce7f7f;
$colorDarkRed: #881616;

$colorOrange: #c45a32;
$colorCodGray: #1d1d1d;
$colorGray: #777777;
$colorAlto: #e0e0e0;
$colorAlabaster: #f7f7f7;
$colorFooterBg: #171413;
$colorHeaderBg: #1c1816;
$colorContactBg: #191919;

html,
body {
	height: 100%;
}
body {
	color: $colorAlabaster;
	font-size: 16px;
	font-weight: 300;
	font-family: "Gotham", sans-serif;
	line-height: 1.6875;
}
* {
	outline: none;
	box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: 1em;
	line-height: 1;
	margin: 0;
	overflow-wrap: normal !important;
}
img {
	vertical-align: top;
	max-width: 100%;
}
a {
	color: $colorOrange;
	text-decoration: none;
	transition: none;
	&:hover {
		text-decoration: none;
		color: $colorOrange;
	}
}
ul,
ol {
	list-style-type: none;
	list-style-position: inside;
	padding: 0;
	margin: 0;
}
p {
	margin: 0;
}
button {
	outline: none;
	&:focus {
		outline: none;
	}
}
[class^="icon-"],
[class*=" icon-"] {
	position: relative;
	vertical-align: middle;
}
.wrapper {
	position: relative;
	min-width: 320px;
	min-height: 100%;
	overflow: hidden;
}

.container {
	padding: 0 36px;
	max-width: none !important;
}

.title {
	h2 {
		font-size: 60px;
		line-height: 1.3;
		font-weight: 200;
		font-family: "Gotham Extra", sans-serif;
		font-style: normal;
		letter-spacing: normal;
		font-style: normal;
		text-transform: uppercase;
		color: $colorOrange;
	}
	h3 {
		font-size: 25px;
		line-height: 1.16;
		font-weight: 300;
		font-style: normal;
		letter-spacing: normal;
		font-style: normal;
		text-transform: uppercase;
		font-family: "Gotham Book", sans-serif;
		color: $colorOrange;
	}
	&-main {
		font-size: 150px;
		line-height: 1.2;
		font-weight: 100;
		font-style: normal;
		letter-spacing: normal;
		line-height: normal;
		text-transform: uppercase;
		color: $colorOrange;
	}
}

.heading {
	font-size: 13px;
	line-height: 19px;
	text-transform: uppercase;

	font-family: "Gotham Book", sans-serif;
}

.bg {
	&-black {
		background: $colorBlack !important;
	}
	&-alto {
		background-color: $colorAlto;
	}
}

.color {
	&-black {
		color: $colorBlack !important;
	}
	&-white {
		color: $colorAlabaster !important;
	}
	&-cod-gray {
		color: $colorCodGray !important;
	}
	&-orange {
		color: $colorOrange;
	}
}

.slick {
	&-arrow {
		position: relative;
		background: transparent;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 31px;
		height: 31px;
		font-size: 0;
		border: 0;
		padding: 0;
		cursor: pointer;
		z-index: 10;
		&:before {
			content: "";
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			font-size: 31px;
			font-family: "svgfont", sans-serif;
			text-align: center;
			transition: font-size 0.5s;
		}
		&:hover {
			&:before {
				font-size: 36px;
			}
		}
	}
	&-prev {
		left: 0;
		&:before {
			content: "\EA04";
		}
	}
	&-next {
		right: 0;
		&:before {
			content: "\EA05";
		}
	}
}

.js-main-info-content {
	opacity: 0;
	// &-text {
	// 	opacity: 0;
	// }
}
.js-main-banner-title,
.js-main-banner-text,
.js-main-banner-btn-read,
.js-main-banner-btn-more,
.js-main-product-title,
.js-main-product-text,
.js-main-benefit-content,
.js-header-menu-back,
// .js-header-menu-nav,
.js-header-menu-info,
.js-news-detail-slider,
.js-news-detail-slider-text,
.js-header-menu-bg-opaity,
.js-news-grid,
.js-news-prev,
.js-news-next,
.js-news-control-time,
.js-news-control-progress,
.js-news-read,
.js-desc-heading,
.js-desc-big,
.js-desc-small,
.js-main-product-drones,
.js-product-info-inner,
.js-news-banner-info {
	opacity: 0;
}

.js-main-banner-title,
.js-main-banner-text,
.js-main-banner-btn-read,
.js-main-banner-btn-more,
.js-main-product-title,
.js-main-product-text,
.js-main-benefit-content,
.js-header-menu-back,
.js-header-menu-info,
.js-news-detail-slider,
.js-news-detail-slider-text,
.js-header-menu-bg-opaity,
.js-news-grid,
.js-news-prev,
.js-news-next,
.js-news-control-time,
.js-news-control-progress,
.js-news-read,
.js-desc-heading,
.js-desc-big,
.js-desc-small,
.js-main-product-drones,
.js-news-banner-info{
	will-change: transform;
}
.js-news-detail-slider {
	// transform: translate(-80%, 0px);
	// will-change: transform;
}
.js-main-product-drones-list {
	li {
		opacity: 0;
		will-change: transform;
	}
}
.js-main-banner-bg {
	transform: scale(1.1);
	// will-change: transform;
}

.js-header-menu-nav {
	li {
		opacity: 0;
		// will-change: transform;
	}
}

.js-product-info-point {
	width: 0;
	height: 0;
	will-change: transform;
}

.js-main-benefit-info-slider  {
	.slick-slide {
		transition: opacity .5s !important;
	}
}

.point-event-none {
	pointer-events: none !important;
}

.section {
	position: relative;
	transition: background 2s;
	&-inner {
		padding: 90px 0 50px;
	}
}

.irs {
	height: 30px;
	&-line {
		top: 0px !important;
		background: transparent !important;
		height: 30px !important;
		border-radius: 1px !important;
		cursor: pointer;
		&:after {
			content: "";
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			height: 1px;
			background: $colorOrange !important;
		}
	}
	&-bar {
		top: 14px !important;
		height: 1px !important;
		background-color: transparent !important;
		cursor: pointer;
	}
	&-handle {
		top: 0 !important;
		width: 1px !important;
		height: 30px !important;
		&:after {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			background: $colorOrange !important;
			width: 30px !important;
			height: 30px !important;
			border-radius: 50% !important;
		}
		cursor: pointer;
		i {
			display: none !important;
		}
	}
}

.opacity {
	&-0 {
		pointer-events: none;
		opacity: 0 !important;
	}
	&-1 {
		pointer-events: all;
		opacity: 1 !important;
	}
}

[data-product-content-block] {
	transition: opacity 2s;
}

[data-product-info-tab-main] {
	.product-info__bottom, .product-info__hint {
		transition: opacity 1s;
	}
	&.open-details {
		position: absolute;
		top: 0;
		left: 96px;
		bottom: 50px;
		right: 96px;

		.product-info__bottom, .product-info__hint {
			opacity: 0;
		}
		.product-info__details {
			opacity: 0;
		}
		.product-info__hint {
			right: -9px;
		}
	}
}

[data-opacity-on-change-theme], [data-show-when-video-ended], [data-theme-white-opacity-range], [data-theme-dark-opacity-range] {
	transition: opacity 1s;
}