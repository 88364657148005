@font-face
	font-family: "svgfont"
	src: url('fonts/svgfont.eot')
	src: url('fonts/svgfont.eot?#iefix') format('eot'), url('fonts/svgfont.woff') format('woff'), url('fonts/svgfont.woff2') format('woff2'), url('fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal

$icon-close: "\EA01"
$icon-i-menu: "\EA02"
$icon-i-arrow-down: "\EA03"
$icon-i-arrow-left: "\EA04"
$icon-i-arrow-right: "\EA05"
$icon-i-grid: "\EA06"
$icon-i-rect: "\EA07"
$icon-i-oblique: "\EA08"
$icon-i-plus: "\EA09"
$icon-i-point: "\EA0A"
$icon-i-soc-fb: "\EA0B"
$icon-i-soc-in: "\EA0C"
$icon-i-soc-instagram: "\EA0D"
$icon-i-soc-twitter: "\EA0E"
$icon-i-sound-on: "\EA0F"
$icon-i-sound-off: "\EA10"


%icon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.icon, [class^="icon-"], [class*=" icon-"]
	@extend %icon
	display: inline-block

.icon-close:before
	content: $icon-close
.icon-i-menu:before
	content: $icon-i-menu
.icon-i-arrow-down:before
	content: $icon-i-arrow-down
.icon-i-arrow-left:before
	content: $icon-i-arrow-left
.icon-i-arrow-right:before
	content: $icon-i-arrow-right
.icon-i-grid:before
	content: $icon-i-grid
.icon-i-rect:before
	content: $icon-i-rect
.icon-i-oblique:before
	content: $icon-i-oblique
.icon-i-plus:before
	content: $icon-i-plus
.icon-i-point:before
	content: $icon-i-point
.icon-i-soc-fb:before
	content: $icon-i-soc-fb
.icon-i-soc-in:before
	content: $icon-i-soc-in
.icon-i-soc-instagram:before
	content: $icon-i-soc-instagram
.icon-i-soc-twitter:before
	content: $icon-i-soc-twitter
.icon-i-sound-on:before
	content: $icon-i-sound-on
.icon-i-sound-off:before
	content: $icon-i-sound-off