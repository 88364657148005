@media screen and (min-width: 768px) {
	.container {
		padding: 0 80px;
	}
	.section {
		&-inner {
			padding: 110px 0 71.5px;
		}
	}
}

@media screen and (min-width: 992px) {
	.section {
		&-inner {
			padding: 110px 0 70.5px;
		}
	}
}

@media screen and (min-width: 1200px) {
	.container {
		padding: 0 97px;
	}
	.section {
		&-inner {
			padding: 150px 0 90px;
		}
	}
}

// Max Width
@media screen and (max-width: 1599px) {
	.header {
		&-menu {
			&__backgrouhnd {
				&__block {
					width: 67.7%;
				}
				&__img {
					&-box {
						width: 58.2%;
					}
				}
			}
		}
	}
	.main {
		&-banner {
			&__title {
				&.v3 {
					max-width: 549px;
				}
			}
		}
		&-features {
			&__slider {
				&__box {
					&-title {
						h2 {
							font-size: 2.7vw;
						}
					}
				}
			}
		}
		&-404 {
			&__backgrouhnd {
				&__block {
					width: 70%;
					border-left: 30.8vw solid transparent;
				}
			}
		}
	}
	.news {
		&-banner {
			&__link {
				&:not(:first-child) {
					margin-left: 0;
				}
			}
			&__control {
				&:not(:first-child) {
					margin-left: 0;
				}
			}
			&__more {
				&:not(:first-child) {
					margin-right: 0px;
				}
			}
		}
	}
	.product {
		&-info {
			&__block {
				// padding-bottom: 50px;
			}
			&__bottom {
				&__body {
					flex-direction: column;
				}
			}
			&__characteristics {
				&:not(:last-child) {
					margin-right: 0;
					margin-bottom: 30px;
				}
			}
		}
	}
	.contact {
		&-banner {
			&__inner {
				// padding-bottom: 60px;
			}
			&__top {
				flex-direction: column-reverse;
				align-items: flex-start;
			}
			&__info {
				min-width: auto;
				&:not(:last-child) {
					margin-right: 0px;
					margin-bottom: 0;
				}
			}
			&__map {
				max-width: 1000px;
				margin: 0 auto;
				&:not(:first-child) {
					margin-bottom: 90px;
				}
				&-hint {
					display: flex;
					width: 50%;
					left: auto;
					right: 0;
					top: calc(100% + 91px);

					font-size: 10px;
				}
			}
			&__bottom {
				display: none;
			}
		}
	}
	.footer {
		&__box {
			min-width: 300px;
		}
	}
}

@media screen and (max-width: 1399px) {
	.header {
		&-menu {
			&__content {
				// &__soc {
				// 	position: absolute;
				// 	left: 96px;
				// }
			}
			&__backgrouhnd {
				&__block {
					// background: $colorHeaderBg;
					// width: 100%;
					// background: #1c1816;
					// border-bottom: 0 solid #1c1816;
					// border-left: 0 solid transparent;
					// &::before {
					// 	content: "";
					// }
				}
				&__img {
					&-box {
						width: 50%;
					}
				}
			}
		}
	}
	.main {
		&-banner {
			&__inner {
				// padding-bottom: 56px;
			}
			&__title {
				&.v3 {
					max-width: 490px;
				}
			}
			&__content {
				&.v2 {
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
				}
			}
			&__text {
				&.v2 {
					&:not(:last-child) {
						margin-bottom: 24px;
					}
				}
				&.v3 {
					max-width: 490px;
				}
			}
			&__bottom {
				&.v2 {
					max-width: none;
				}
			}
		}
		&-desc {
			&__info {
				max-width: 644px;
			}
		}
		&-404 {
			&__backgrouhnd {
				&__block {
					width: 75%;
				}
			}
		}
	}
	.product {
		&-info {
			&__block {
				// min-height: 768px;
				min-height: 0;
			}
			&__bottom {
				&__head {
					max-width: 450px;
				}
			}
		}
	}
	.contact {
		&-banner {
			&__map {
				max-width: 768px;
			}
		}
	}
	.footer {
		&__top {
			display: grid;
			grid-template-columns: calc(50% + 8px) 50%;
			grid-gap: 47px 0;
			padding-right: 0;
			&:not(:last-child) {
				margin-bottom: 94px;
			}
		}
		&__products {
			grid-row-start: 1;
			grid-row-end: 3;
		}
		&__subscribe {
			&__form {
				&__input {
					width: 238px;
				}
			}
		}
		&__info {
			max-width: none;
			display: grid;
			grid-template-columns: calc(50% + 8px) 50%;
		}
		&__soc {
			grid-column-start: 1;
			grid-column-end: 2;
			grid-row-start: 1;
			grid-row-end: 2;
		}
	}
}

@media screen and (max-width: 1199px) {
	body {
		font-size: 13px;
		line-height: 20px;
	}
	.title {
		h2 {
			font-size: 35px;
			// line-height: 78px;
		}
		h3 {
			font-size: 18px;
			line-height: 1.45;
			// line-height: 29px;
		}
		&-main {
			font-size: 75px;
			font-weight: 100;
		}
	}
	.heading {
		font-size: 10px;
	}
	.btn {
		font-size: 10px;
	}
	.switch {
		&-text {
			font-size: 10px;
		}
	}
	.header {
		top: 45px;
		&-logo {
			width: 42px;
			top: 45px;
			left: 80px;
		}
		&-toggle,
		&-back {
			top: 55px;
			right: 80px;
		}
		&-menu {
			&__logo {
				left: 80px;
				top: 45px;
				width: 42px;
			}
			&__content {
				// padding: 56px 0 44px;
				padding: 70.5px 0;
				margin-right: 80px;
				width: 464px;
				&__back {
					.btn {
						font-size: 10px;
					}
				}
				&__nav {
					padding: 48px 0 65px 39px;
					ul {
						li {
							&:not(:last-child) {
								margin-bottom: 42px;
							}
							a {
								font-size: 35px;
								& .header-menu__content__nav-line {
									height: 28px;
								}
							}
						}
					}
				}
				&__soc {
					position: absolute;
					left: 80px;
				}
			}
			&__backgrouhnd {
				&__block {
					background: $colorHeaderBg;
					width: 100%;
					border-bottom: 0 solid #1c1816;
					border-left: 0 solid transparent;
				}
				&__img-box {
					display: none;
				}
			}
		}
	}
	.main {
		&-banner {
			&__breadcrumbs {
				top: 118px;
			}
			&__title {
				position: relative;
				top: auto;
				transform: none;
				margin-top: auto;
				&:not(:last-child) {
					margin-bottom: 20px;
				}
				&.v2 {
					position: absolute;
					top: 47%;
					transform: translateY(-50%);
					margin-top: 0;
					&:not(:last-child) {
						margin-bottom: 0;
					}
					h2 {
						color: $colorAlabaster;
					}
				}
				&.v3 {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					max-width: 315px;
					margin-top: 0;
				}
			}
			&__text {
				max-width: 452px;
				&:not(:last-child) {
					margin-bottom: 30px;
				}
				&.v2,
				&.v3 {
					&:not(:last-child) {
						margin-bottom: 30px;
					}
				}
				&.v3 {
					max-width: 315px;
				}
			}
			&__content {
				margin-top: 0;
				&.v2,
				&.v3 {
					margin-top: auto;
				}
				&.v3 {
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
				}
			}
			&__bottom {
				.btn {
					&.v2 {
						color: $colorCodGray;
					}
				}
			}
		}
		&-info {
			&__inner {
				// padding-top: 100px;
			}
			&__top {
				&:not(:last-child) {
					margin-bottom: 26px;
				}
			}
			&__content {
				.main-info__box {
					&:not(:last-child) {
						margin-right: 16px;
					}
				}
			}
			&__box {
				&-img {
					&:not(:last-child) {
						margin-bottom: 30px;
					}
				}
				&-heading {
					&:not(:last-child) {
						margin-bottom: 1px;
					}
				}
				&-title {
					&:not(:last-child) {
						margin-bottom: 19px;
					}
					h3 {
						font-size: 22px;
					}
				}
				&-text {
					max-width: 296px;
					&:not(:last-child) {
						margin-bottom: 17px;
					}
				}
			}
		}
		&-benefit {
			&__inner {
				display: flex;
				flex-direction: column-reverse;
				align-items: flex-start;
				grid-gap: 0;
			}
			&__box {
				&:not(:first-child) {
					margin-bottom: 22px;
				}
			}
			&__info {
				max-width: 500px;
			}
			&__slider {
				width: 100%;
			}
		}
		&-product {
			&__inner {
				// padding-bottom: 45px;
			}
			&__top {
				width: auto;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				top: 33.3%;
				right: 0;
				transform: translateY(-50%);

				width: 100%;
				max-width: 296px;
				margin-right: 18px;
				.btn {
					margin-left: 0;
				}
			}
			&__title {
				&:not(:last-child) {
					margin-bottom: 23px;
				}
			}
			&__drones {
				&-title {
					&:not(:last-child) {
						margin-bottom: 9px;
					}
				}
				&__item {
					&:not(:last-child) {
						margin-bottom: 4px;
					}
					&.active,
					&:hover {
						.main-product__drones__line {
							width: 62px;
						}
					}
				}
				&__link {
					padding-left: 78px;
					line-height: 27px;
				}
			}
			&__desc {
				max-width: 296px;
				padding-bottom: 6px;
				margin-right: 18px;
			}
		}
		&-benefit {
			&__info {
				max-width: 310px;
				z-index: 10;
				&-heading {
					&:not(:last-child) {
						margin-bottom: 5px;
					}
				}
				&-title {
					&:not(:last-child) {
						margin-bottom: 20px;
					}
				}
			}
			&__slider {
				&:not(:first-child) {
					margin-bottom: -10px;
				}
				.slick {
					&-next {
						right: -53px;
						top: 45%;
					}
					&-prev {
						left: -53px;
						top: 45%;
					}
				}
			}
		}
		&-features {
			&:after {
				height: 144px;
			}
			&__breadcrumbs {
				top: 120px;
				left: 80px;
				.breadcrumbs {
					ul {
						li {
							a,
							span {
								font-size: 10px;
							}
						}
					}
				}
			}
			&__slider {
				&__arrow {
					.slick {
						&-arrow {
							top: 43.5%;
						}
						&-prev {
							left: 27px;
						}
						&-next {
							right: 27px;
						}
					}
				}
				&__box {
					padding: 61px 10px 90px;
					&-heading {
						font-size: 10px;
						&:not(:last-child) {
							margin-bottom: 10px;
						}
					}
					&-title {
						h2 {
							font-size: 3.418vw;
						}
					}
				}
			}
		}
		&-video {
			&__inner {
				// padding: 55px 88px;
				&.section-inner {
					padding: {
						left: 80px;
						right: 80px;
					}
					&.active {
						padding: 0;
					}
				}
			}
		}
		&-desc {
			padding: 69px 0 172px;
			&.with-full-page {
				.main-desc {
					&__inner {
						// padding-top: 100px;
					}
				}
			}
			&__info {
				&-big {
					font-size: 16px;
					line-height: 27px;
					&:not(:last-child) {
						margin-bottom: 76px;
					}
					&.v2 {
						font-size: 16px;
						line-height: 27px;
						&:not(:last-child) {
							margin-bottom: 50px;
						}
					}
				}
				&-big,
				&-small {
					p,
					ol,
					ul {
						&:not(:last-child) {
							margin-bottom: 21px;
						}
					}
					ul {
						li {
							&:before {
								top: 10px;
							}
						}
					}
				}
			}
		}
		&-404 {
			&__inner {
				// padding: 98px 80px;
			}
			&__content {
				padding-top: 50px;
			}
			&__middle {
				max-width: 352px;
			}
			&__bottom {
				margin-left: 0;
				max-width: 352px;
			}
			&__title {
				h2 {
					line-height: normal;
				}
			}
			&__heading {
				margin-left: 0;
				&:not(:last-child) {
					margin-bottom: 15px;
				}
			}
			&__info {
				&:not(:last-child) {
					margin-bottom: 28px;
				}
			}
			&__backgrouhnd {
				&__block {
					width: 79.4%;
					border-left: 34.8vw solid transparent;
				}
			}
		}
		&-terms {
			&__title {
				&:not(:last-child) {
					margin-bottom: 35px;
				}
			}
		}
	}
	.news {
		&-banner {
			&__inner {
				// padding-bottom: 45px;
			}
			&__link {
				&:not(:first-child) {
					margin-left: 110px;
				}
				.btn {
					&:not(:last-child) {
						margin-right: 15px;
					}
				}
			}
			&__control {
				&:not(:first-child) {
					margin-left: 79px;
				}
				&__time {
					min-width: 64px;
					font-size: 10px;
					line-height: 19px;
					&:not(:last-child) {
						margin-right: 17px;
					}
				}
				&__progress {
					width: 217px;
				}
			}
			&__more {
				margin-right: -20px;
			}
			&__heading {
				&:not(:last-child) {
					margin-bottom: 5px;
				}
			}
			&__title {
				max-width: 265px;
			}
		}
		&-slider {
			&__inner {
				// padding-top: 100px;
			}
			&__top {
				&:not(:last-child) {
					margin-bottom: 20px;
				}
			}
			&__counter {
				font-size: 10px;
				line-height: 19px;
			}
			&__content {
				width: 100%;
				max-width: 645px;
			}
		}
		&-list {
			// padding: 167px 0 60px;
			&__inner {
				// // padding-top: 100px;
				// padding-top: 118px;
			}
			&__top {
				// display: none;
				&:not(:last-child) {
					margin-bottom: 48px;
				}
			}
			&__content {
				grid-gap: 48px 16px;
				&.v2 {
					grid-gap: 58px 0;
				}
			}
		}
		&-box {
			max-width: 846px;
			grid-template-columns: 10fr 7fr;
			grid-gap: 0 125px;
			&__img {
				padding-bottom: 56.8%;
			}
			&__title {
				&:not(:last-child) {
					margin-bottom: 18px;
				}
			}
			&__text {
				&:not(:last-child) {
					margin-bottom: 16px;
				}
			}
		}
		&-desc {
			&__row {
				&:not(:last-child) {
					margin-bottom: 90px;
				}
			}
			&__block {
				&:not(:last-child) {
					margin-right: 50px;
				}
			}
			&__quote {
				&:not(:last-child) {
					margin-bottom: 170px;
				}
				&:not(:first-child) {
					margin-top: 80px;
				}
			}
			&__text {
				font-size: 13px;
				&-big {
					font-size: 20px;
				}
				p,
				ol,
				ul {
					&:not(:last-child) {
						margin-bottom: 21px;
					}
				}
				ul {
					li {
						&:before {
							top: 10px;
						}
					}
				}
			}
		}
	}
	.product {
		&-page {
			&__toggle-theme {
				left: 80px;
				top: 100px;
				transform: none;
			}
			&__back {
				right: 80px;
				width: 296px;
				.btn {
					font-size: 10px;
				}
			}
		}
		&-info {
			&__inner {
				&.section-inner {
					padding: {
						top: 0;
						bottom: 0;
					}
				}
			}
			&__block {
				// padding: 45px 0;
				padding: 80px 0;
			}
			&__hint {
				width: 311px;
				top: auto;
				bottom: 80px;
				transform: none;
			}
			&__bottom {
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				max-width: 300px;
				&.v2 {
					flex-direction: row;
					align-items: flex-end;
					justify-content: space-between;
					max-width: none;
				}
				&__head {
					&:not(:last-child) {
						margin-bottom: 20px;
					}
					&.v2 {
						max-width: 360px;
						&:not(:last-child) {
							margin-bottom: 0px;
						}
					}
				}
				&__body {
					padding-bottom: 0;
				}
				&__text {
					max-width: 296px;
					&:not(:last-child) {
						margin-bottom: 0;
					}
				}
			}
			// &__back {
			// 	right: 80px;
			// 	width: 296px;
			// 	font-size: 10px;
			// }
		}
		&-characteristics {
			&__inner {
				// padding: 100px 0;
			}
			&__top {
				&:not(:last-child) {
					margin-bottom: 48px;
				}
			}
			&__list {
				display: grid;
				grid-template-columns: max-content max-content max-content max-content;
				max-width: 1024px;
				width: 100%;
				margin: 0 auto;
				grid-gap: 90px 50px;
				justify-content: space-between;
			}
			&__box {
				&-title {
					h2 {
						font-size: 75px;
						line-height: 1;
					}
				}
				&-text {
					color: $colorGray;
					font-size: 12px;
				}
			}
		}
	}
	.contact {
		&-banner {
			&__inner {
				// padding: 101px 0 30px;
			}
			&__info {
				&-heading {
					&:not(:last-child) {
						margin-bottom: 10px;
					}
				}
			}
		}
		&-info {
			&__inner {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center; // remove if need slider

				// padding-bottom: 74px;
				// padding-top: 74px; // remove if need slider
			}
			&__top {
				padding: 35px 0 0 0;
				grid-template-columns: minmax(0, 1fr);
			}
			&__bottom {
				display: grid;
				// grid-template-columns: 1fr 1fr 1fr;
				grid-template-columns: 1fr 2fr;
				align-items: flex-end;
				justify-content: space-between;
				&-block {
					&:nth-child(2) {
						justify-content: center;
					}
					&:nth-child(3) {
						justify-content: flex-end;
					}
				}
			}
			&__slider {
				margin: 0 -80px;
				&-box {
					margin: 0 7.6vw;
					padding-bottom: calc(74.8% - 15.2vw);
				}
				.slick-list {
					margin: 0 -22vw;
				}
			}
			&__desc {
				&-heading {
					&:not(:last-child) {
						margin-bottom: 10px;
					}
				}
			}
			&__data {
				margin-bottom: 2px;
				&-box {
					max-width: 160px;
				}
			}
			&__item {
				grid-gap: 50px;
			}
		}
	}
	.breadcrumbs {
		&.v2 {
			margin-left: 0;
		}
		ul {
			li {
				a,
				span {
					font-size: 10px;
					line-height: 27px;
					color: $colorWhite;
				}
			}
		}
	}
	.switch {
		&-hint {
			opacity: 1;
		}
	}
	.footer {
		padding: 61px 0 45px;
		&-title {
			&:not(:last-child) {
				margin-bottom: 13px;
			}
		}
		&__products {
			&__list {
				li {
					&:not(:last-child) {
						margin-bottom: 38px;
					}
					a {
						font-size: 35px;
						line-height: normal;
					}
				}
			}
			.footer {
				&-title {
					&:not(:last-child) {
						margin-bottom: 15px;
					}
				}
			}
		}
		&__contact {
			&__list {
				li {
					&:not(:last-child) {
						margin-bottom: 16px;
					}
				}
			}
		}
	}
	[data-product-info-tab-main] {
		&.open-details {
			position: absolute;
			// top: 45px;
			// bottom: 45px;
			left: 80px;
			right: 80px;
			.product-info__hint {
				right: 7px;
			}
		}
	}
}

@media screen and (max-width: 991px) {
	.slick {
		&-arrow {
			&:hover {
				&:before {
					font-size: 31px;
				}
			}
		}
	}
	.header {
		top: 49px;
		&-logo {
			top: 49px;
		}
		&-toggle,
		&-back {
			top: 59px;
		}
		&-menu {
			&__logo {
				top: 49px;
			}
			&__content {
				width: 339px;
				// padding: 56px 0;
				padding: 71.5px 0;
				&__back {
					.btn {
						font-size: 10px;
					}
				}
				&__nav {
					padding: 56px 0 65px 43px;
					ul {
						li {
							&:not(:last-child) {
								margin-bottom: 52px;
							}
							a {
								& .header-menu__content__nav-line {
									&:before {
										content: none;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.main {
		&-banner {
			&__inner {
				// padding-top: 119px;
				// padding-bottom: 78px;
			}
			&__background {
				&.v2 {
					display: none;
				}
			}
			&__breadcrumbs {
				position: relative;
				top: 0;
				&:not(:last-child) {
					margin-bottom: 33px;
				}
			}
			&__img {
				width: 100%;
				height: 476px;
				position: relative;
				margin-top: auto;
				&:not(:last-child) {
					margin-bottom: 33px;
				}
				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
			&__title {
				&.v2 {
					top: 50%;
				}
				&.v3 {
					position: relative;
					top: 0;
					transform: none;
					max-width: 452px;
					&:not(:last-child) {
						margin-bottom: 26px;
					}
				}
			}
			&__content {
				&.v3 {
					margin-top: 0;
				}
			}
			&__text {
				&.v3 {
					max-width: 452px;
				}
			}
		}
		&-info {
			&__top {
				margin-bottom: 30px;
			}
		}
		&-product {
			&__inner {
				// padding-bottom: 62px;
			}
			&__desc {
				margin-right: 0;
			}
			&__top {
				top: 43%;
				right: 0;
				transform: translateY(-50%);

				width: auto;
				max-width: none;
				margin-right: 0;
				align-items: flex-end;
			}
		}
		&-benefit {
			&__slider {
				&:not(:first-child) {
					margin-bottom: 34px;
				}
			}
		}
		&-features {
			&__slider {
				&__arrow {
					.slick {
						&-arrow {
							top: 50%;
						}
						&-prev {
							left: 27px;
						}
						&-next {
							right: 27px;
						}
					}
				}
				&__box {
					padding: 61px 10px 70.5px;
					&-heading {
						&:not(:last-child) {
							margin-bottom: 0px;
						}
					}
					&-title {
						font-size: 4vw;
					}
				}
			}
		}
		&-video {
			&__inner {
				// padding: 80px;
			}
		}
		&-desc {
			padding: 64px 0 172px;
			&__info {
				max-width: none;
				&-big {
					font-size: 16px;
					line-height: 27px;
					&:not(:last-child) {
						margin-bottom: 76px;
					}
				}
				&-big,
				&-small {
					p {
						&:not(:last-child) {
							margin-bottom: 21px;
						}
					}
				}
			}
		}
		&-404 {
			&__inner {
				// padding: 62px 80px;
			}
			&__content {
				padding-top: 93px;
			}
			&__backgrouhnd {
				&__block {
					width: 100%;
					border-left: 0;
				}
			}
		}
	}
	.news {
		&-banner {
			&__inner {
				// padding-bottom: 78px;
			}
			&__link {
				&:not(:first-child) {
					margin-left: 30px;
				}
			}
			&__control {
				&:not(:first-child) {
					margin-left: 0px;
				}
			}
		}
		&-slider {
			&__inner {
				// padding-bottom: 78px;
			}
			&__box {
				&-img {
					padding-bottom: 77.42%;
					&:not(:last-child) {
						margin-bottom: 29px;
					}
				}
			}
		}
		&-list {
			&__content {
				&.v2 {
					grid-gap: 72px 0;
				}
			}
			&__top {
				&:not(:last-child) {
					margin-bottom: 43px;
				}
			}
		}
		&-box {
			grid-template-columns: 3fr 4fr;
			grid-gap: 0 95px;
			&__img {
				padding-bottom: 76.7%;
			}
			&__content {
				align-self: flex-end;
			}
			&__title {
				&:not(:last-child) {
					margin-bottom: 16px;
				}
			}
			&__text {
				&:not(:last-child) {
					margin-bottom: 19px;
				}
			}
		}
		&-desc {
			&__row {
				flex-direction: column;
				&:not(:last-child) {
					margin-bottom: 60px;
				}
			}
			&__block {
				max-width: none;
				flex: auto;
				&:not(:last-child) {
					margin-right: 0;
					margin-bottom: 30px;
				}
				&:first-child {
					max-width: none;
				}
			}
			&__content {
				max-width: none;
			}
			&__heading {
				&:not(:last-child) {
					margin-bottom: 20px;
				}
			}

			&__text {
				&-big {
					font-size: 16px;
				}
				&-column {
					column-count: 1;
				}
				p,
				ol,
				ul {
					&:not(:last-child) {
						margin-bottom: 21px;
					}
				}
			}
			&__quote {
				&:not(:first-child) {
					margin-top: 50px;
				}
				&:not(:last-child) {
					margin-bottom: 100px;
				}
				&-img {
					&:not(:last-child) {
						margin-bottom: 30px;
					}
				}
				&-text {
					font-size: 16px;
					&:not(:last-child) {
						margin-bottom: 30px;
					}
				}
			}
			&__btn {
				margin-top: 44px;
			}
		}
	}
	.product {
		&-page {
			&__toggle-theme {
				top: 120px;
			}
			&__back {
				top: 126px;
				width: auto;
			}
		}
		&-info {
			&__inner {
				&.section-inner {
					padding: {
						top: 0;
						bottom: 0;
					}
				}
			}
			&__block {
				// min-height: 1024px;
				// padding: 74px 0 62px;
			}
			&__hint {
				width: 175px;
			}
			&__hint {
				bottom: 80px;
			}
			&__bottom {
				&.v2 {
					flex-direction: column;
					align-items: flex-start;
					justify-content: flex-start;
				}
				&__head {
					max-width: none;
					&.v2 {
						max-width: none;
						&:not(:last-child) {
							margin-bottom: 20px;
						}
					}
				}
				&__body {
					width: auto;
				}
			}
			// &__back {
			// 	width: auto;
			// }
		}
		&-characteristics {
			&__list {
				grid-template-columns: 1fr 1fr 1fr;
				grid-gap: 60px 40px;
			}
			&__box {
				&-title {
					h2 {
						font-size: 75px;
						line-height: 1;
					}
				}
			}
		}
	}
	.contact {
		&-banner {
			.container {
				height: 100%;
			}
			&__inner {
				height: 100%;
				// padding: 202px 0 66px;
			}
			&__info {
				&-heading {
					&:not(:last-child) {
						margin-bottom: 11px;
					}
				}
			}
			&__map {
				margin: 0 -40px;
				width: calc(100% + 80px);
				&:not(:first-child) {
					margin-bottom: 80px;
				}
				&-points {
					a {
						font-size: 1.9vw;
					}
				}
				&-hint {
					display: none;
				}
			}
			&__bottom {
				margin-top: auto;
				display: flex;
				grid-gap: 0;
			}
			&__hint {
				font-size: 10px;
				grid-column-start: 1;
				grid-column-end: 2;
			}
		}
		&-info {
			&__inner {
				// padding-top: 136px;
				// padding-bottom: 74px;
				min-height: auto;
			}
			&__top {
				padding: 35px 0 0 0;
				margin: 0;
				&:not(:last-child) {
					margin-bottom: 112px;
				}
			}
			&__bottom {
				// margin-top: auto;
				margin-top: 0;
				display: grid;
				grid-template-columns: 1fr;
				align-items: flex-end;
				justify-content: space-between;
			}
			&__slider {
				.slick-list {
					margin: 0 -24vw;
				}
				.slick {
					&-arrow {
						&:before {
							font-size: 22px;
						}
					}
				}
			}
			&__desc {
				&-heading {
					&:not(:last-child) {
						margin-bottom: 10px;
					}
				}
			}
			&__data {
				margin-bottom: 2px;
				&-box {
					max-width: 160px;
				}
			}
			&__mobile {
				&__desc {
					margin: auto 0;
				}
			}
		}
	}

	[data-product-info-tab-main] {
		&.open-details {
			position: absolute;
			// top: 74px;
			// bottom: 62px;
			left: 80px;
			right: 80px;
		}
	}
}

@media screen and (max-width: 767px) {
	.title {
		h2 {
			font-size: 25px;
			line-height: 1.56;
		}
		&-main {
			font-size: 70px;
		}
	}
	.btn {
		font-size: 13px;
	}
	.header {
		top: 29px;
		&-logo {
			top: 29px;
			left: 36px;
		}
		&-toggle,
		&-back {
			right: 36px;
			top: 39px;
		}
		&-menu {
			&__logo {
				top: 29px;
				left: 36px;
			}
			&__content {
				width: 100%;
				max-width: 289px;
				// padding: 36px 0 53px;
				padding: 50px 0;
				margin-right: 36px;
				&__back {
					margin-right: 3px;
					.btn {
						font-size: 10px;
					}
				}
				&__nav {
					padding: 53px 0 20px 39px;
					ul {
						li {
							a {
								font-size: 30px;
							}
							&:not(:last-child) {
								margin-bottom: 37px;
							}
						}
					}
				}
				&__info {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
				}
				&__terms {
					font-size: 10px;
					&:not(:last-child) {
						margin-bottom: 10px;
					}
				}
				&__soc {
					left: 0;
					position: relative;
				}
			}
		}
	}
	.section {
		-webkit-transform: translate3d(0, 0, 0);
	}
	.main {
		&-banner {
			&__inner {
				// padding-top: 106px;
				// padding-bottom: 56px;
			}
			&__breadcrumbs {
				&:not(:last-child) {
					margin-bottom: 30px;
				}
			}
			&__img {
				height: 344px;
				position: relative;
				&:not(:last-child) {
					margin-bottom: 22px;
				}
			}
			&__title {
				&:not(:last-child) {
					margin-bottom: 29px;
				}
				&.v2 {
					top: 48%;
				}
				&.v3 {
					&.title h2 {
						line-height: 1.4;
					}
					&:not(:last-child) {
						margin-bottom: 21px;
					}
				}
			}
			&__text {
				&:not(:last-child) {
					margin-bottom: 24px;
				}
				&.v2 {
					&:not(:last-child) {
						margin-bottom: 24px;
					}
				}
				&.v3 {
					&:not(:last-child) {
						margin-bottom: 26px;
					}
				}
			}
		}
		&-info {
			&__inner {
				// padding: 100px 0 56px;
			}
			&__content {
				// flex-direction: column;
				// .main-info__box {
				// 	&:not(:last-child) {
				// 		margin-right: 6px;
				// 	}
				// }
			}
			&__box {
				// flex: none;
				// &:not(:last-child) {
				// 	margin-right: 0px;
				// }
				&-img {
					&:not(:last-child) {
						margin-bottom: 40px;
					}
				}
				&-heading {
					&:not(:last-child) {
						margin-bottom: 7px;
					}
				}
				&-title {
					&:not(:last-child) {
						margin-bottom: 20px;
					}
				}
				&-text {
					max-width: 296px;
					&:not(:last-child) {
						margin-bottom: 23px;
					}
				}
			}
		}
		&-product {
			&__inner {
				// padding-bottom: 70px;
			}
			&__background {
				img {
					position: absolute;
					top: 100px;
					transform: translateX(-50%);
					min-width: 1170px;
				}
			}
			&__top {
				top: 49.3%;
			}
			&__title {
				text-align: right;
				&:not(:last-child) {
					margin-bottom: 11px;
				}
			}
			&__drones {
				&__item {
					&:not(:last-child) {
						margin-bottom: 2px;
					}
					&.active,
					&:hover {
						.main-product__drones__line {
							width: 42px;
						}
					}
				}
				&__link {
					padding-left: 52px;
				}
				&__line {
					height: 1px;
				}
			}
		}
		&-benefit {
			&__info {
				max-width: 100%;
				&-title {
					&:not(:last-child) {
						margin-bottom: 8px;
					}
				}
			}
			&__slider {
				&:not(:first-child) {
					margin-bottom: 22px;
				}
				&::before {
					width: calc(50% - 68px);
				}
				&:after {
					width: calc(50% - 68px);
				}
				.slick {
					&-list {
						margin: 0 -25.5vw;
					}
					&-prev {
						left: -10px;
					}
					&-next {
						right: -10px;
					}
				}
				// .slick-slide.slick-center
				.slick-slide.slick-current {
					.main-benefit__slider-box {
						margin: 0 -3.5vw;
						padding-bottom: calc(100% - 9vw);
					}
				}
			}
		}
		&-features {
			&:after {
				height: 140px;
				background-image: linear-gradient(
					180deg,
					rgba(0, 0, 0, 0) 0%,
					rgba(0, 0, 0, 0.5) 100%
				);
			}
			&__breadcrumbs {
				top: 100px;
				left: 36px;
			}
			&__slider {
				&__arrow {
					.slick {
						&-arrow {
							color: $colorOrange;
						}
					}
				}
				&__box {
					padding: 61px 10px 50px;
					&-heading {
						&:not(:last-child) {
							margin-bottom: 3px;
						}
					}
					&-title {
						h2 {
							font-size: 25px;
						}
					}
				}
			}
		}
		&-desc {
			padding: 40px 0 80px;
			&.with-full-page {
				.main-desc {
					&__inner {
						// padding: 100px 0 56px;
					}
				}
			}
			&__info {
				&-big {
					&:not(:last-child) {
						margin-bottom: 38px;
					}
				}
			}
		}
		&-video {
			&__inner {
				padding: 0px;
				&.section-inner {
					padding: 0;
				}
			}
		}
		&-404 {
			&__inner {
				// padding: 43px 36px 53px;
			}
			&__content {
				padding-top: 0;
			}
			&__title {
				h2 {
					font-size: 30px;
					line-height: 1.36;
				}
			}
			&__info {
				&:not(:last-child) {
					margin-bottom: 25px;
				}
			}
			&__bottom {
				.btn {
					font-size: 10px;
				}
			}
		}
		&-terms {
			&__title {
				&:not(:last-child) {
					margin-bottom: 25px;
				}
			}
		}
	}
	.news {
		&-banner {
			&__inner {
				// padding: 100px 0 56px;
			}
			&__content {
				display: grid;
				grid-template-columns: 1fr max-content max-content;
				grid-gap: 20px 20px;
			}
			&__control {
				grid-row-start: 1;
				grid-row-end: 1;
				grid-column-start: 1;
				grid-column-end: 2;

				&__time {
					min-width: 90px;
				}
				&__progress {
					width: 100%;
				}
			}
			&__link {
				justify-self: flex-end;
				&:not(:first-child) {
					margin-left: 0;
				}
			}
			&__more {
				justify-self: flex-end;
			}
			&__title {
				max-width: 190px;
			}
		}
		&-slider {
			&__inner {
				// padding: 100px 0 56px;
			}
		}
		&-list {
			// // padding: 127px 0 60px;
			&__inner {
				// padding: 106px 0 56px;
			}
			&__top {
				&:not(:last-child) {
					margin-bottom: 43px;
				}
				&.v2 {
					&:not(:last-child) {
						margin-bottom: 26px;
					}
				}
			}
			&__content {
				grid-template-columns: 1fr;
				grid-gap: 61px 0;
				&.v2 {
					grid-gap: 57px 0;
				}
			}
		}
		&-box {
			grid-template-columns: 1fr;
			grid-gap: 26px 0;

			&__img {
				padding-bottom: 55.96%;
			}
			&__content {
				align-self: flex-start;
			}
			&__title {
				&:not(:last-child) {
					margin-bottom: 18px;
				}
			}
			&__text {
				&:not(:last-child) {
					margin-bottom: 24px;
				}
			}
		}
		&-desc {
		}
	}
	.product {
		&-page {
			&__toggle-theme {
				left: 34px;
				top: 110px;
			}
			&__back {
				top: 117px;
				right: 36px;
				&.btn {
					font-size: 10px;
				}
			}
		}
		&-info {
			&__inner {
				// padding-top: 180px;
				// padding-bottom: 70px;
				&.section-inner {
					padding: {
						top: 50px;
						bottom: 50px;
					}
				}
			}
			&__block {
				margin-top: auto;
				min-height: auto;
				height: auto;
				// padding: 120px 0 70px;
				padding: 0;
			}
			// &__toggle-theme {
			// 	top: 0;
			// 	position: relative;
			// 	&:not(:last-child) {
			// 		margin-bottom: 50px;
			// 	}
			// }
			&__details {
				position: relative;
				top: 0;
				left: 0;
				transform: none;
				margin: 0 -36px;
				width: calc(100% + 72px);
				margin-top: auto;
				&:not(:last-child) {
					margin-bottom: 58px;
				}
				&-item {
					&.active {
						.product-info__details__points {
							&-pin {
								width: 25px !important;
								height: 25px !important;
							}
							&-hint {
								opacity: 1;
								&.show {
									&-left {
										transform: translate(-10px, -50%);
									}
									&-right {
										transform: translate(10px, -50%);
									}
								}
							}
						}
					}
				}
				// &:before {
				// 	content: '';
				// 	position: absolute;
				// 	top: 50%;
				// 	left: 50%;
				// 	width: 100%;
				// 	height: 100%;
				// 	transform: translate(-50%, -50%);
				// 	background-image: radial-gradient(50.13% 226.6%, rgba(0, 0, 0, 0) 0%, #000 100%);
				// }
				&-img {
					position: relative;
					padding-bottom: 56.25%;
				}
				&__points {
					&-pin {
						width: 20px;
						height: 20px;
						font-size: 14px;
					}
				}
			}
			&__bottom {
				margin-top: 0;
				max-width: none;
			}
			&__heading {
				&:not(:last-child) {
					margin-bottom: 7px;
				}
			}
			// &__back {
			// 	top: 136px;
			// 	font-size: 10px;
			// right: 36px;
			// }
			&__video {
				position: relative;
				top: 0;
				left: 0;
				transform: none;
				margin: 0 -36px;
				width: calc(100% + 72px);
				// padding-bottom: 56.25%;
				margin-top: auto;
				&:not(:last-child) {
					margin-bottom: 58px;
				}

				&-content {
					position: relative;
					padding-bottom: 56.25%;
				}
			}
		}
		&-characteristics {
			&__list {
				grid-template-columns: 1fr 1fr;
				grid-gap: 40px 20px;
			}
			&__box {
				text-align: center;
				&-title {
					h2 {
						font-size: 40px;
						line-height: 1;
					}
				}
				&-text {
					font-size: 12px;
					margin-top: 0;
				}
				&__sub {
					margin-bottom: 0;
				}
				&__sup {
					margin-top: 0;
				}
			}
		}
	}
	.contact {
		&-banner {
			&__inner {
				// padding: 116px 0 50px;
			}
			&__top {
				margin: auto 0;
			}
			&__bottom {
				margin: 0;
			}
			&__info {
				&-heading {
					&:not(:last-child) {
						margin-bottom: 6px;
					}
				}
			}
			&__map {
				margin: 0 -20px;
				width: calc(100% + 40px);
				&:not(:first-child) {
					margin-bottom: 90px;
				}
				&-points a {
					font-size: 16px;
				}
			}
		}
		&-info {
			&__inner {
				// padding-top: 97px;
				// padding-bottom: 60px;
			}
			&__top {
				padding: 0px 0 0 0;
				margin: 0;
				&:not(:last-child) {
					margin-bottom: 13px;
				}
			}
			&__bottom {
				margin-top: auto;
				display: grid;
				grid-template-columns: 1fr;
				align-items: flex-end;
				justify-content: flex-start;
				grid-gap: 25px 0;
			}
			&__item {
				grid-template-columns: 1fr;
				grid-gap: 25px;
			}
			&__slider {
				position: relative;
				margin: 0;
				width: 100%;
				&::before {
					content: none;
				}
				&:after {
					content: none;
				}
				&-box {
					margin: 0 10px;
					padding-bottom: 114%;
					position: relative;
				}
				.slick-list {
					margin: 0 -10px;
				}
				.slick-track {
					display: flex;
					align-items: center;
					flex-wrap: nowrap;
					height: auto;
					justify-content: center;
				}
				.slick-slide {
					padding: 0px 0px;
					&.slick-current,
					&.slick-center {
						padding: 0px 0px;
					}
				}
				// .slick-slide.slick-center
				.slick-slide.slick-current {
					transition: all 1s;
					transform: scale(1);
					.contact-info__slider-box {
						padding-bottom: 114%;
						margin: 0 10px;
						transform: scale(1);
					}
				}
			}
			&__desc {
				&-heading {
					&:not(:last-child) {
						margin-bottom: 6px;
					}
				}
			}
			&__data {
				margin-bottom: 0px;
				&-box {
					padding-left: 24px;
					max-width: 184px;
					&:before {
						left: 0;
					}
				}
			}
			&__mobile {
				&__desc {
					&:not(:last-child) {
						margin-bottom: 60px;
					}
				}
			}
		}
	}
	.footer {
		padding: 126px 0 53px;
		&__top {
			grid-template-columns: 1fr;
			grid-gap: 63px 0;
			&:not(:last-child) {
				margin-bottom: 61px;
			}
		}
		&__products {
			&__list {
				li {
					&:not(:last-child) {
						margin-bottom: 24px;
					}
					a {
						font-size: 30px;
					}
				}
			}
			.footer {
				&-title {
					&:not(:last-child) {
						margin-bottom: 18px;
					}
				}
			}
		}
		&__subscribe {
			&__form {
				&__input {
					width: 100%;
					max-width: 244px;
				}
			}
		}
		&__info {
			grid-template-columns: 1fr;
			grid-gap: 16px 0;
		}
		&__soc {
			grid-row-start: 2;
			grid-row-end: 3;
		}
		&__box {
			min-width: 0;
		}
	}
	.switch {
		&-slider {
			&:not(:last-child) {
				margin-right: 30px;
			}
		}
	}
	[data-product-info-tab-main] {
		&.open-details {
			position: absolute;
			top: auto;
			// bottom: 70px;
			left: 36px;
			right: 36px;
		}
	}
}

@media screen and (max-width: 575px) {
	.main {
		&-info {
			&__content {
				flex-direction: column;
				.main-info__box {
					&:not(:last-child) {
						margin-right: 5px;
					}
				}
				.slick {
					&-list {
						margin: 0 -5px;
					}
				}
			}
			&__box {
				flex: none;
				margin: 0 5px;
			}
		}
	}
	.news {
		&-slider {
			&__box {
				margin: 0 5px;
				&-img {
					padding-bottom: 143.32%;
					&:not(:last-child) {
						margin-bottom: 12px;
					}
				}
			}
			.slick {
				&-list {
					margin: 0 0 0 -36px;
					padding-left: 82px;
				}
			}
		}
	}
}
